import React, { FC } from 'react';
import { isEmpty } from 'lodash'; 
import { withRouter, RouteComponentProps } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_INVOICE } from '../graphql/queries';
import { formatMonthDayYear } from '../../../utils/utils';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import WorkIcon from '@material-ui/icons/Work';
import { Link } from 'react-router-dom';
import { IconButton, makeStyles } from '@material-ui/core';
import { DELETE_INVOICE } from '../graphql/mutations';
import useNotifications from '../../../Notifications/useNotifications';
import QueryGuard from '../../../Components/QueryGuard';

interface Props extends RouteComponentProps<{ id: string }> {

}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
}));

const ReceiptDetails: FC<Props> = ({ match, history }) => {
  const { data, loading, error } = useQuery(GET_INVOICE, { variables: { id: match.params.id }})
  const { addError, addSuccess } = useNotifications();
  const { header } = useStyles();
  const [deleteInvoice] = useMutation(DELETE_INVOICE, {
    onCompleted: () => {
      addSuccess("Invoice deleted successfully!");
      history.push('/receipts')
    },
    onError: () => addError("There was an error deleting this invoice")
  });

  return (
    <QueryGuard data={data && data.invoice} loading={loading} error={!!error}>
      {data && data.invoice && (
        <>
        <div className={header}>
          <h1>Receipt details</h1>
          <div>
            {!isEmpty(data.invoice.bookingId) && (
              <Link to={`/bookings/${data.invoice.bookingId}`}>
                <IconButton>
                  <WorkIcon />
                </IconButton>
              </Link>
            )}
            <Link to={{ state: { receipt: data.invoice }, pathname: `/receipts/edit/${match.params.id}`}}>
              <IconButton>
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton onClick={() => deleteInvoice({ variables : { id: match.params.id }})}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
          {data.invoice.name && (
            <>
            <h2>Name</h2>
            {data.invoice.name}
            </>
          )}
          <h2>Full name</h2>
          {data.invoice.fullName}
          {data.invoice.profession && (
            <>
            <h2>Profession</h2>
            {data.invoice.profession}
            </>
          )}
          {data.invoice.address && (
            <>
            <h2>Address</h2>
            {data.invoice.address}
            </>
          )}
          {data.invoice.city && (
            <>
            <h2>City</h2>
            {data.invoice.city}
            </>
          )}
          {data.invoice.telephone && (
            <>
            <h2>Telephone</h2>
            {data.invoice.telephone}
            </>
          )}
          {data.invoice.afm && (
            <>
            <h2>AFM</h2>
            {data.invoice.afm}
            </>
          )}
          {data.invoice.doy && (
            <>
            <h2>Doy</h2>
            {data.invoice.doy}
            </>
          )}
          {data.invoice.series && (
            <>
            <h2>Document series</h2>
            {data.invoice.series}
            </>
          )}
          {data.invoice.documentType && (
            <>
            <h2>Document type</h2>
            {data.invoice.documentType}
            </>
          )}
          <h2>Payment Type</h2>
          {data.invoice.paymentType || '-'}
          <h2>Date</h2>
          {data.invoice.date ? formatMonthDayYear(+data.invoice.date) : '-'}
          <h2>Total</h2>
          {data.invoice.total}
          <h2>Hotel name</h2>
          {data.invoice.hotelName}
          <h2>Number of people</h2>
          {data.invoice.numberOfPeople || '-'}
          <h2>Check in</h2>
          {data.invoice.checkIn ? formatMonthDayYear(+data.invoice.checkIn) : '-'}
          <h2>Check out</h2>
          {data.invoice.checkOut ? formatMonthDayYear(+data.invoice.checkOut) : '-'}
        </>
      )}

    </QueryGuard>
  );
};

export default withRouter(ReceiptDetails);

import React from 'react';
import { Formik, FormikProps, Form } from 'formik';
import { Grid, TextField, Button, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { RequestFormType } from './types';

interface RequestFormProps {
  initialValues?: RequestFormType;
  onSubmit: (value: RequestFormType) => void
}

const customerSchema = Yup.object().shape({
  name: Yup.string().required("Customers name is required.")
});

const defaultValues: RequestFormType = {
  name: '',
  email: '',
  phone: '',
  notes: '',
}

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: 'column',
    maxWidth: "75%"
  }
}));

const RequestForm = ({ onSubmit, initialValues = defaultValues }: RequestFormProps) => {
  const { form, button } = useStyles();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={customerSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, handleChange, handleBlur, values }: FormikProps<RequestFormType>) => (
        <Form className={form}>

              <TextField
                id="outlined-name"
                label="Name"
                name="name"
                value={values.name}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.name && !!touched.name)}
                helperText={(!!errors.name && !!touched.name) && errors.name}
                margin="normal"
              />

              <TextField
                id="outlined-name"
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.email && !!touched.email)}
                helperText={(!!errors.email && !!touched.email) && errors.email}
                margin="normal"
              />

              <TextField
                id="outlined-name"
                label="Phone"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.phone && !!touched.phone)}
                helperText={(!!errors.phone && !!touched.phone) && errors.phone}
                margin="normal"
              />

              <TextField
                id="outlined-name"
                label="Notes"
                name="notes"
                value={values.notes}
                multiline={true}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.notes && !!touched.notes)}
                helperText={(!!errors.notes && !!touched.notes) && errors.notes}
                margin="normal"
              />
              <div>
                <Button variant="contained" className={button} type="submit" color="primary">
                  Create request
                </Button>
              </div>
        </Form>
      )}
    </Formik>
  )
}

export default RequestForm;
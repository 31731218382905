import gql from "graphql-tag";

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($firstName: String!, $lastName: String!, $email: String!, $phone: String!, $afm: String) {
    createCustomer(firstName: $firstName, lastName: $lastName, email: $email , phone: $phone, afm: $afm) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($id: ID!, $values: CustomerInput) {
    updateCustomer(id: $id, values: $values) 
  }
`;

export const CREATE_BOOKING = gql`
  mutation CreateBooking($id: ID!, $values: BookingInput) {
    createBooking(id: $id, values: $values)
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id)
  }
`;

export const ADD_CUSTOMER_REVIEW = gql`
  mutation addCustomerReview($id: ID!, $review: Boolean) {
    addCustomerReview(id: $id, review: $review)
  }
`;

export const DELETE_BOOKING = gql`
  mutation DeleteBooking($bookingId: ID!) {
    deleteBooking(bookingId: $bookingId)
  }
`;

export const CREATE_HOTEL = gql`
  mutation CreateHotel($values: HotelInput) {
    createHotel(values: $values) {
      id
      name
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($type: String, $id: String, $action: String) {
    createDocument(type: $type, id: $id, action: $action)
  }
`;

export const MAKE_PAYMENT = gql`
  mutation MakePayment($bookingId: ID!, $transaction: TransactionInput) {
    makePayment(bookingId: $bookingId, transaction: $transaction)
  }
`;
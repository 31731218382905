import React, { FC, useState } from 'react';
import { Fab, makeStyles, FormControl, Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_INVOICES } from './graphql/queries';
import { Receipt } from './types';
import Select from '../../Components/Select';
import QueryGuard from '../../Components/QueryGuard';
import { formatMoney } from '../../utils/utils';
import Table from '../../Components/Table';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
  },
  spacing: {
    marginRight: '1rem',
  },
  input: {
    minWidth: '12rem',
    marginTop: '1rem'
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center'
  },

  button: {
    marginLeft: '1rem'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '0.5rem',
    '&:hover': {
      background: '#fafafa'
    }
  },
  zebra: {
    background: '#fcfcfc'
  },
  dataContainer: {
    marginTop: '2rem'
  },
  header: {
    fontWeight: 'bold',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '0.5rem',
    borderBottom: '1px solid #cfcfcf'
  }
}));

type ReceiptType = 'invoice' | 'receipt' | 'all';

interface Props extends RouteComponentProps {}

const Receipts: FC<Props> = ({ history }) => {
  const [dateFrom, setDateFrom] = useState<string>(moment().subtract(1, 'month').format('YYYY-MM-DD'));
  const [dateUntil, setDateUntil] = useState<string>(moment().format('YYYY-MM-DD'));
  const [receiptType, setReceiptType] = useState<ReceiptType>('all');
  console.log(dateUntil)
  const getIsVat = () => {
    if (receiptType === 'all') {
      return undefined;
    }

    if (receiptType === 'invoice') {
      return true;
    }

    return false;
  }
  console.log(getIsVat(), receiptType)
  const [getReceipt, { data, error, loading, called }] = useLazyQuery(GET_INVOICES, {
    variables: {
      dateFrom,
      dateUntil,
      isVat: getIsVat()
    },
    fetchPolicy: 'network-only'
  })
  const { fab, spacing, input, searchBox, button, zebra, row, header, dataContainer } = useStyles();

  const handleSearch = () => getReceipt();

  const handleSetDateFrom = (value) => {
    const formattedDate = moment(value).format('YYYY-MM-DD');

    setDateFrom(formattedDate);
  }

  const handleSetDateUntil = (value) => {
    console.log(value)
    const formattedDate = moment(value).format('YYYY-MM-DD');

    setDateUntil(formattedDate);
  }

  const renderReceiptTable = (receipts: Receipt[]) => {
    const tableData = receipts.map((receipt) => ({
      data: {
        name: `${receipt.fullName} ${receipt.name ? ` - ${receipt.name}` : ''}`,
        hotel: receipt.hotelName,
        totalAmount: formatMoney(receipt.total ? +receipt.total : 0)
      },
      onRowClick: () => history.push(`/receipts/${receipt.id}`),
    }))

    return <Table tableData={tableData} />
  }

  const renderReceipts = (receipts: Receipt[]) => (
    <div className={dataContainer}>
      <Grid className={header} container>
        <Grid item xs={4}>Name</Grid>
        <Grid item xs={4}>Hotel</Grid>
        <Grid item xs={4}>Total amount</Grid>
      </Grid>
      {receipts.map((receipt: Receipt, i: number) => {
        const isZebra = i % 2 === 0;

        return (
          <div key={i} onClick={() => history.push(`/receipts/${receipt.id}`)} className={`${row} ${(isZebra ? zebra : '')}`}>
            <Grid container>
              <Grid item xs={4}>{receipt.fullName} {receipt.name ? ` - ${receipt.name}` : ''}</Grid>
              <Grid item xs={4}>{receipt.hotelName}</Grid>
              <Grid item xs={4}>{receipt.total}</Grid>
            </Grid>
          </div> 
        )
      })}
    </div>
  )

  return (
    <>
      <h1>Receipts</h1>
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={searchBox}>
            <div>
            <KeyboardDatePicker
              onBlur={() => {}}
              onFocus={() => {}}
              format="dd/MM/yyyy"
              className={spacing}
              margin="normal"
              id="date-picker-inline"
              inputVariant="outlined"
              label="Date from"
              maxDate={moment().format('YYYY-MM-DD')}
              onChange={handleSetDateFrom}
              value={dateFrom}
            />
            <KeyboardDatePicker
              onBlur={() => {}}
              onFocus={() => {}}
              format="dd/MM/yyyy"
              margin="normal"
              className={spacing}
              id="date-picker-inline"
              inputVariant="outlined"
              label="Date until"
              maxDate={moment().format('YYYY-MM-DD')}
              onChange={handleSetDateUntil}
              value={dateUntil}
            />
            <FormControl className={input}>
              <Select
                value={receiptType}
                label="Receipt type"
                onChange={(event) => setReceiptType(event.target.value as ReceiptType)}
                options={[
                  { name: 'Invoice', value: 'invoice' },
                  { name: 'Receipt', value: 'receipt' },
                  { name: 'All', value: 'all' }
                ]}
              />
            </FormControl>
            </div>
            <div className={button}>
              <Button variant="outlined" onClick={handleSearch}>Search</Button>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </div>
      
      <QueryGuard data={called ? data && data.invoices : [true]} loading={loading} error={!!error}>
        {data && data.invoices && (
          <div style={{ marginTop: '2rem' }}>
            {renderReceiptTable(data.invoices)}
          </div>)}
      </QueryGuard>
      
      <Fab 
        color="primary"
        onClick={() => history.push('/receipts/new')}
        aria-label="add"
        className={fab}
      >
        <AddIcon />
      </Fab>
    </>
  )
}

export default withRouter(Receipts);
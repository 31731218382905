import React from 'react';
import { TextField, makeStyles, Fab } from '@material-ui/core';
import CustomerList from './CustomerList';
import AddIcon from '@material-ui/icons/Add';
import { RouteComponentProps } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { GET_CUSTOMERS } from './graphql/queries';
import { Customer } from './types/types';
import useHandleAuthError from '../../utils/hooks/useHandleAuthError';
import QueryGuard from '../../Components/QueryGuard';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    background: 'white',
    borderColor: 'white',
    marginBottom: '2rem'
  },
  grid: {
    textAlign: 'right',
  },
  fab: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
  }
}));

interface CustomersProps extends RouteComponentProps {

}

const Customers = ({ history }: CustomersProps) => {
  const onError = useHandleAuthError()
  const { textField, fab } = useStyles();
  const [keyword, setKeyword] = React.useState<string>('');
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMERS, { variables: { query: keyword }, onError });


  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
    }
  }

  return (
    <>
      <h1>Customers</h1>
      <Fab 
        color="primary"
        onClick={() => history.push("/customers/new")}
        aria-label="add"
        className={fab}
      >
        <AddIcon />
      </Fab>
      <TextField
        id="inline-search"
        label="Search"
        placeholder="Search customers by email, first name, last name...."
        type="search"
        variant="outlined"
        name="search"
        onKeyPress={onKeyPress}
        className={textField}
        margin="normal"
      />
    <QueryGuard loading={loading} error={!!error} data={data && data.customers || []}>
      {data && data.customers && (
        <>
          <CustomerList customers={data.customers}/>
        </>
      )}
    </QueryGuard>
    </>
  )
}

export default Customers;
import React from 'react';

import CustomerInfo from './CustomerInfo';
import { GET_CUSTOMER } from '../graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { withRouter, RouteComponentProps } from 'react-router';
import { MatchParams } from '../types/types';
import useHandleAuthError from '../../../utils/hooks/useHandleAuthError';
import CustomerBookings from './CustomerBookings';
import QueryGuard from '../../../Components/QueryGuard';
import CustomerReview from './CustomerReview';
import CustomerTickets from './CustomerTickets';
import ContextMenu from '../../Requests/ContextMenu';
import CustomerGiftVouchers from './CustomerGiftVouchers/CustomerGiftVouchers';

const CustomerDetails = ({ match, history } : RouteComponentProps<MatchParams>) => {
  const id = match.params.id;
  const onError = useHandleAuthError();

  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { id },
    onError
  });

  return (
    <QueryGuard error={!!error} loading={loading} data={data.customer}>
      {(data && data.customer) && (
          <div style={{ paddingBottom: '4rem' }}>
            <CustomerInfo customer={data.customer} hasBookings={!!data.customer.bookings.length} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <CustomerReview id={id} reviewed={data.customer.review} />
              <ContextMenu items={[
                { label: 'Create booking', onClick: () => history.push(`/bookings/new/${id}`)}, 
                { label: 'Create ticket', onClick: () => history.push(`/tickets/new/${id}`)},
                { label: 'Create gift voucher', onClick: () => history.push(`/gift-vouchers/new/${id}`)}
              ]} />
            </div>
            <CustomerBookings bookingIds={data.customer.bookings} customerId={id} />
            <CustomerTickets customerId={id} />
            <CustomerGiftVouchers customerId={id} />
          </div>
      )}

    </QueryGuard>
  )
}

export default withRouter(CustomerDetails);
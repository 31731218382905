import React from 'react';
import { Formik, Form } from 'formik';
import { Grid, TextField, makeStyles, Button } from '@material-ui/core';
import * as Yup from 'yup';
import { HotelInput } from './types';

const hotelSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
});


const defaultInitialValues: HotelInput = {
  name: '',
  phone: '',
  address: '',
}

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));

interface Props {
  onSubmit: (values: HotelInput) => void;
  initialValues?: HotelInput;
}

const HotelForm = ({ onSubmit, initialValues }: Props) => {
  const { input, button } = useStyles();

  
  return (
    <Formik
      initialValues={initialValues || defaultInitialValues}
      onSubmit={onSubmit}
      validationSchema={hotelSchema}
    >
      {({values, handleChange, handleBlur, errors, touched}) => (
        <Form>
          <Grid container spacing={3}>
          <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Hotel Name"
                name="name"
                value={values.name}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.name && !!touched.name)}
                helperText={(!!errors.name && !!touched.name) && errors.name}
                margin="normal"
                className={input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Phone"
                name="phone"
                variant="outlined"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.phone && !!touched.phone)}
                helperText={(!!errors.phone && !!touched.name) && errors.phone}
                margin="normal"
                className={input}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-name"
                label="Address"
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={(!!errors.address && !!touched.address)}
                helperText={(!!errors.address && !!touched.address) && errors.address}
                margin="normal"
                className={input}
              />
            </Grid>
          </Grid>
          <div className={button}>
            <Button type="submit" variant="contained" color="primary">Submit</Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default HotelForm; 
import { gql } from "apollo-boost";

export const GET_GIFT_CARDS = gql`
  query giftCards($search: String, $customerId: String) {
    giftCards(search: $search, customerId: $customerId) {
      id
      code
      amount
      usedOn {
        type
        id
      }
    }
  }
`;

export const GET_GIFT_VOUCHER_DETAILS = gql`
  query getGiftCard($id: String!) {
    getGiftCard(id: $id) {
      id
      code
      amount
      customer {
        firstName
        lastName
        id
      }
      usedOn {
        type
        id
      }
      customerPayment {
        total {
          amount
          paid
          date
          remaining
        }
        deposit {
          amount
          paid
          date
          remaining
        }
        name 
        payments {
          id
          date
          bank
          code
          amount
        }
        notes
      }
    }
  }
`
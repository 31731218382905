import { createContext, useContext } from 'react';
import { StringLiteral } from 'typescript';

interface Props {
  firstName: string;
  lastName: string;
  id: string;
  setUser: (userFirstName: string, userLastName: string, id: string) => void;
  unsetUser: () => void;
};

const UserContext = createContext<Props>({ firstName: '', lastName: '', id: '', setUser: () => {}, unsetUser: () => {} });

const useUserDetails = () => useContext(UserContext);

export { useUserDetails };

export default UserContext;
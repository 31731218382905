import React, { FC, useState } from 'react';
import { Backdrop, Button, Card, CardActionArea, CardActions, CardContent, Fab, Grid, Modal, TextField, Typography, makeStyles } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ROOM_TYPES } from './graphql/queries';
import { CREATE_ROOM_TYPE, DELETE_ROOM_TYPE } from './graphql/mutations';
import useNotifications from '../../../Notifications/useNotifications';
import AddIcon from '@material-ui/icons/Add';


interface Props {

}


const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
  },
  modal: {
    display: 'flex',
    paddingTop: '10rem',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    height: '25%',
    width: '50%',
    padding: theme.spacing(2, 4, 3),
  },
}));

const RoomTypes: FC<Props> = () => {
  const { fab, modal, modalPaper } = useStyles()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [textInput, setTextInput] = useState<string>("")
  const { data, refetch } = useQuery(ROOM_TYPES);
  const roomTypes = data && data.roomTypes || [];
  const { addError, addSuccess } = useNotifications();
  const [deleteRoomType] = useMutation(DELETE_ROOM_TYPE, { 
    onError: () => addError("There was an error deleting this room type."), 
    onCompleted: (completedData) => {
      if(completedData && completedData.deleteRoomType) {
        addSuccess("Room type deleted.") 
        refetch();
      } else {
        addError("There was an error deleting this room type.")
      }
    }
  });

  const [createRoomType] = useMutation(CREATE_ROOM_TYPE, { 
    onError: () => addError("There was an error creating this room type."), 
    onCompleted: (completedData) => {
      console.log({completedData})
      if(completedData && completedData.createRoomType) {
        addSuccess("Room type created successfully.")
        setIsModalOpen(false)
        refetch()
      } else {
        addError("There was an error creating this room type.")
      }
    } 
  });


  const handleDeleteRoomType = async (value: string) => {
    await deleteRoomType({ variables: { type: value }})
  }

  const handleCreateRoomType = async (value: string) => {
    await createRoomType({ variables: { type: value }})
  }

  const handleClose = () => {
    setIsModalOpen(false);
    setTextInput("")
  }

  return (
    <>
    <h1>
      Room types
    </h1>
    <Fab 
        color="primary"
        onClick={() => setIsModalOpen(true)}
        aria-label="add"
        className={fab}
      >
        <AddIcon />
      </Fab>
      <Grid container spacing={2}>
    {roomTypes.map((roomType) => (
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {roomType}
              </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={() => handleDeleteRoomType(roomType)} size="small">Delete</Button>
            </CardActions>
          </Card>
        </Grid>
      
      ))}
      </Grid>
      <Modal
        className={modal}
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
      }}
        >
          <div className={modalPaper}>
            <h2>New room type</h2>
            <TextField value={textInput} onChange={(e) => setTextInput(e.target.value)} style={{ marginBottom: '1rem', width: '100%' }} />
            <div>
              <Button color="primary" disabled={!textInput.length} onClick={() => handleCreateRoomType(textInput)} variant='contained'>Create</Button>
            </div>
          </div>
        </Modal>
  </>
  )
};

export default RoomTypes;
import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import HotelForm from '../HotelForm';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_HOTEL } from '../graphql/mutations';
import useNotifications from '../../../Notifications/useNotifications';
import { HotelInput } from '../HotelForm/types';
import { GET_HOTELS } from '../graphql/queries';

interface Props extends RouteComponentProps {

}

const NewHotel: FC<Props> = ({ history }) => {
  const { addError, addSuccess} = useNotifications()
  const [createHotel] = useMutation(CREATE_HOTEL, 
    { 
      onCompleted: () => {
          addSuccess('Hotel created successfully.')
          history.push('/hotels')
      },
      onError: () => addError('Error creating hotel. Please try again.'),
      refetchQueries:[{ query: GET_HOTELS }],
      awaitRefetchQueries: true
    }
  );

  const onSubmit = async (values: HotelInput) => {
    await createHotel({ variables: { values } });
  }
  return (
      <>
        <h1>New hotel</h1>
        <HotelForm onSubmit={onSubmit} />
      </>
  )   
};

export default withRouter(NewHotel);
import gql from "graphql-tag";

export const ACTIVE_REQUESTS = gql`
  query activeRequests($adminId: String) {
    activeRequests(adminId: $adminId) {
      id
      name
      email
      phone
      started
      startedBy {
        id
        firstName
        lastName
        email
      }
      notes
      createdBy {
        firstName
        lastName
        email
      }
      createdAt
    }
  }
`;

export const REQUEST = gql`
  query request($id: String!) {
    request(id: $id) {
      id
      name
      email
      phone
      notes
    }
  }
`;
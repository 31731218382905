import { useMutation } from '@apollo/react-hooks';
import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import useNotifications from '../../../Notifications/useNotifications';
import { CREATE_INVOICE } from '../graphql/mutations';
import { GET_INVOICES } from '../graphql/queries';
import ReceiptForm from '../ReceiptForm';
import { Receipt } from '../types';

interface Props extends RouteComponentProps {

}

const NewReceipt: FC<Props> = ({ history }) => {
  const [createInvoice] = useMutation(CREATE_INVOICE, {
    onError: () => addError('Something went wrong, please try again.'),
    onCompleted: () => { 
      addSuccess('Invoice created');
      history.push('/receipts');
    },
    refetchQueries: [{ query: GET_INVOICES }]
  });
  const { addError, addSuccess } = useNotifications();

  const onSubmit = async (values: Receipt) => {
    try {
      const result = await createInvoice({ variables: {
        input: values
      }})
      console.log(result)
    } catch(e) {
      console.log(e)
    }
  }

  return (
    <>
      <ReceiptForm title="New receipt" onSubmit={onSubmit} />
    </>
  );
};

export default withRouter(NewReceipt);
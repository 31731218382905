import gql from "graphql-tag";

export const DELETE_ROOM_TYPE = gql`
  mutation deleteRoomType($type: String!) {
    deleteRoomType(type: $type)
  }
`

export const CREATE_ROOM_TYPE = gql`
  mutation createRoomType($type: String!) {
    createRoomType(type: $type)
  }
`
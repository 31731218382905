import React, { FC, useState } from 'react';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, Button } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_BOOKINGS_BY_DATE } from './graphql/queries';
import { Booking } from '../Customers/types/types';
import { formatMoney, formatMonthDayYear } from '../../utils/utils';
import QueryGuard from '../../Components/QueryGuard';
import Table from '../../Components/Table';
import Select from '../../Components/Select';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
  },
  spacing: {
    marginRight: '1rem',
  },
  input: {
    minWidth: '12rem',
    marginTop: '1rem'
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '0.5rem',
    '&:hover': {
      background: '#fafafa'
    }
  },
  zebra: {
    background: '#fcfcfc'
  },
  button: {
    marginLeft: '1rem'
  },
  dataContainer: {
    marginTop: '2rem'
  },
  header: {
    fontWeight: 'bold',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '0.5rem',
    borderBottom: '1px solid #cfcfcf'
  }
}));

const Bookings: FC<RouteComponentProps> = ({ history }) => {
  const [dateFrom, setDateFrom] = useState<string>(moment().subtract(1, 'month').format('YYYY-MM-DD'));
  const [dateUntil, setDateUntil] = useState<string>(moment().format('YYYY-MM-DD'));
  const [dateField, setDateField] = useState<'checkIn' | 'checkOut' | 'reservationDate'>('reservationDate');
  const { fab, spacing, searchBox, button, row, dataContainer, zebra, header } = useStyles();
  const [getBookings, { data ,error, loading, called }] = useLazyQuery(GET_BOOKINGS_BY_DATE, { variables: { dateFrom, dateUntil, fieldName: dateField }})
  const handleSearch = () => getBookings();

  const handleSetDateFrom = (value) => {
    const formattedDate = moment(value).format('YYYY-MM-DD');

    setDateFrom(formattedDate);
  }

  const handleSetDateUntil = (value) => {
    const formattedDate = moment(value).format('YYYY-MM-DD');

    setDateUntil(formattedDate);
  }

  const renderBookingTable = (bookings: Booking[]) => {

    const tableData = bookings.map((booking) => {
      if (!booking.customer) {
        console.log(booking.id)
      }

      return { 
        data: {
          name: `${booking.customer.lastName} ${booking.customer.firstName}`,
          hotel: booking.hotel? booking.hotel.name : booking.packageName,
          checkIn: formatMonthDayYear(+booking.checkIn),
          checkOut: formatMonthDayYear(+booking.checkOut),
          adults: booking.adults,
          children: booking.children,
          infants: booking.infants,
          partner: booking.partnerPayment.name,
          totalAmount: formatMoney(booking.customerPayment.total.amount)
        },
        onRowClick: () => history.push(`/bookings/${booking.id}`)
      }
    })
    return (<Table tableData={tableData} />);
  }

  return (
    <>
      <h1>Bookings</h1>
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <div className={searchBox}>
            <div>

              <Select 
                options={[{ name: 'Date of reservation', value: 'reservationDate'}, { name: 'Check in', value: 'checkIn' }, { name: 'Check out', value: 'checkOut'} ]}
                onChange={(event) => {
                  console.log(event)
                  setDateField(event.target.value)
                }}
                value={dateField}
                label="Date"
                style={{ top: '0.25rem', marginRight: '1rem'}}
              />
            </div>
            <div>
            <KeyboardDatePicker
              format="yyyy/MM/dd"
              className={spacing}
              margin="normal"
              id="date-picker-inline"
              label="Date from"
              onFocus={() => {}}
              onBlur={() => {}}
              inputVariant="outlined"
              onChange={handleSetDateFrom}
              value={dateFrom}
            />
            <KeyboardDatePicker
              format="yyyy/MM/dd"
              onFocus={() => {}}
              onBlur={() => {}}
              margin="normal"
              className={spacing}
              id="date-picker-inline"
              inputVariant="outlined"
              label="Date until"
              onChange={handleSetDateUntil}
              value={dateUntil}
            />
            </div>
            <div className={button}>
              <Button variant="outlined" onClick={handleSearch}>Search</Button>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </div>
      <QueryGuard data={called ? data && data.allBookings : [true]} loading={loading} error={!!error}>
        {data && data.allBookings && data.allBookings && (
          <div style={{ marginTop: '2rem' }}>
            {renderBookingTable(data.allBookings)}
          </div>
          )}
      </QueryGuard>
    </>
  );
};

export default withRouter(Bookings);
import React from 'react';
import { LOGIN_ADMIN } from './graphql/queries';
import { CircularProgress } from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import { useAuth } from '../../AuthContext/AuthContext';
import LoginForm, { AdminLoginInput } from './LoginForm/LoginForm';
import useNotifications from '../../Notifications/useNotifications';
import UserContext, { useUserDetails } from '../../UserContext/UserContex';

const picture = require('./logo.png');

const Login = () => {
  const { login } = useAuth()
  const [fetchLogin, { loading, error, called, data }] = useLazyQuery(LOGIN_ADMIN);
  const { addError, addSuccess } = useNotifications()
  const { setUser: setUserName } = useUserDetails();

  const submitLogin = (values: AdminLoginInput) => {
    fetchLogin({ variables: { input: values }});
  }
  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    addError('An error occoured during login')
  }

  if (called && data) {
    console.log(data)
    addSuccess('You logged in successfully')
    login(data.login.token);
    setUserName(data.login.firstName, data.login.lastName, data.login.id);
    return <>Success</>
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '75%', margin: 'auto'}}>
      <img style={{ paddingTop: '2rem'}} src={picture} />
      <LoginForm onSubmit={submitLogin} />
    </div>
  )
}

export default Login;
import { useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';
import { ANALYTICS } from './graphql/query';
import { Divider, Grid, Paper } from '@material-ui/core';
import { formatMoney } from '../../utils/utils';

interface Props {

}

const Analytics: FC<Props> = () => {
  const { data, loading, error  } = useQuery(ANALYTICS);

  const analytics = data && data.getAnalytics || {};

  const renderSection = (title: string, details: any) => (
    <div style={{ marginTop: '2rem'}}>
    <h3>{title}</h3>
    <Grid container spacing={3}>
      <Grid md={4} xs={12}  item>
        <Paper style={{ minWidth: '8rem', padding: '1rem', background: '#006663'}} elevation={3}>
          <div style={{ fontSize: '1rem', color: 'white' }}>Paid</div>
          <div style={{ fontSize: '1.5rem', color: 'white'}}>{formatMoney(details ? details.customerTransactions : 0)}</div>  
        </Paper>
      </Grid>
      <Grid item md={4} xs={12}>
        <Paper style={{ minWidth: '8rem', padding: '1rem', background: '#F3A949' }}  elevation={3}>
          <div style={{ fontSize: '1rem', color: 'white' }}>Unpaid</div>
          <div style={{ fontSize: '1.5rem', color: 'white'}}>{formatMoney(details ? details.customerRemaining : 0)}</div>  
        </Paper>
      </Grid>
    </Grid>

  </div>
  )

  const calculateTotal = () => {
    const booking = analytics.booking ? analytics.booking.customerTransactions - analytics.booking.partnerTransactions : 0;
    const ticket = analytics.ticket ? analytics.ticket.customerTransactions - analytics.ticket.partnerTransactions : 0;
    const giftVoucher = analytics.giftVoucher ? analytics.giftVoucher.customerTransactions - analytics.giftVoucher.partnerTransactions : 0;

    return booking + ticket + giftVoucher;

  }


  return (
    <>
      <h2>This month</h2>
      {renderSection("Bookings", analytics.booking)}
      {renderSection('Tickets', analytics.ticket)}
      {renderSection('Gift vouchers', analytics.giftVoucher)}
      <Divider style={{ marginTop: '2rem'}}/>
          <div style={{ marginTop: '2rem'}}>
      <h3>Total</h3>
        <Grid container spacing={3}>
          <Grid md={4} xs={12}  item>
            <Paper style={{ minWidth: '8rem', padding: '1rem', background: '#006663'}} elevation={3}>
              <div style={{ fontSize: '1rem', color: 'white' }}>Profit</div>
              <div style={{ fontSize: '1.5rem', color: 'white'}}>{calculateTotal()}</div>  
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
    )
  };

export default Analytics;
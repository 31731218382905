import gql from "graphql-tag";

export const CREATE_TICKET = gql`
  mutation createTicket($values: TicketInput) {
    createTicket(values: $values) {
      id
    } 
  }
`;

export const DELETE_TICKET_PAYMENT = gql`
  mutation deleteTicketPayment($ticketId: ID!, $transactionId: String, $paymentType: String) {
    deleteTicketPayment(ticketId: $ticketId, transactionId: $transactionId, paymentType: $paymentType)
  }
`;

export const ADD_TICKET_PAYMENT = gql`
  mutation makeTicketPayment($ticketId: ID!, $transaction: TransactionInput) {
    makeTicketPayment(ticketId: $ticketId, transaction: $transaction)
  }
`;

export const DELETE_TICKET = gql`
  mutation deleteTicket($id: String!) {
    deleteTicket(id: $id)
  }
`

export const UPDATE_TICKET_PAYMENT_NOTES = gql`
  mutation addTicketPaymentNote($ticketId: ID!, $paymentType: String, $notes: String) {
    addTicketPaymentNote(ticketId: $ticketId, paymentType: $paymentType, notes: $notes)
  }
`;

import { Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {

}

const OperationsSettings: FC<Props> = () => {
  const settings = [
    {
      title: 'Admins',
      tagline: 'Manage admin user details.',
      link: "/operations/admins"
    },
    {
      title: 'Room types',
      tagline: 'Edit room types available in the booking form.',
      link: "/operations/room-types"
    },
  ]
  return (
    <>
      <h1>
        Operations settings
      </h1>
      <Grid container spacing={2}>
        {settings.map((setting) => (
          <Grid item xs={4}>
          <Link style={{ textDecoration: 'none' }} to={setting.link}>
          <Card>
          <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {setting.title}
                </Typography>
                <Typography variant="body2">
                  {setting.tagline}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
            </Link>
        </Grid>
        ))}
        
      </Grid>
    </>
  )
};

export default OperationsSettings;
import gql from "graphql-tag";

export const GET_MESSAGE_STASUSES = gql`
  query messageStatuses {
    messageStatuses {
      event
      timestamp
      to
      subject
    }
  }
`
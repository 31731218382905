import gql from "graphql-tag";

export const CREATE_REQUEST = gql`
  mutation createRequest($id: String!, $input: RequestInput) {
    createRequest(id: $id, input: $input)
  }
`;

export const DELETE_REQUEST = gql`
  mutation deleteRequest($id: String!) {
    deleteRequest(id: $id) 
  }
`
export const MARK_REQUEST_COMPLETED = gql`
  mutation markRequestCompleted($id: String!, $adminId: String!) {
    markRequestCompleted(id: $id, adminId: $adminId) 
  }
`

export const SET_REQUEST_STARTED = gql`
  mutation setRequestStarted($id: String!, $adminId: String!, $started: Boolean) {
    setRequestStarted(id: $id, adminId: $adminId, started: $started) 
  }
`

export const EDIT_REQUEST = gql`
  mutation editRequest($id: String!, $input: RequestInput) {
    editRequest(id: $id, input: $input)
  }
`;
import { gql } from "apollo-boost"

export const MAKE_GIFT_VOUCHER_PAYMENT = gql`
  mutation makeGiftCardPayment($id: ID!, $transaction: TransactionInput) {
    makeGiftCardPayment(id: $id, transaction: $transaction)
  }
`

export const DELETE_GIFT_VOUCHER_PAYMENT = gql`
  mutation deleteGiftCardPayment($id: ID!, $transactionId: String) {
    deleteGiftCardPayment(id: $id, transactionId: $transactionId)
  }
`

export const ADD_GIFT_CARD_PAYMENT_NOTE = gql`
  mutation addGiftCardPaymentNote($id: ID!, $notes: String) {
    addGiftCardPaymentNote(id: $id, notes: $notes)
  }
`

export const CREATE_GIFT_VOUCHER = gql`
  mutation createGiftCard($values: GiftCardInput) {
    createGiftCard(values: $values)
  }
`
export const DELETE_GIFT_VOUCHER = gql`
  mutation deleteGiftCard($id: String!) {
    deleteGiftCard(id: $id)
  }
`
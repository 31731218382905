import React, { FC, useEffect, useRef, useState } from 'react';
import { Payment, Booking, PaymentTypeEnum, Transaction } from '../../../Customers/types/types';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Button, Input, makeStyles, TextField } from '@material-ui/core';
import Table from '../../../../Components/Table';
import AddIcon from '@material-ui/icons/Add';
import { formatMoney, formatMonthDayYear } from '../../../../utils/utils';
import PaymentForm from '../../../DashBoard/ActionItem/PaymentForm';
import Grid from '@material-ui/core/Grid/Grid';

const createTableData = (transactions: Transaction[], action: (id) => void, shouldShowAction?: (transaction: Omit<Transaction, 'type'>) => boolean) => {
  return transactions.map((transaction) => {
    const getAction = () => {
      if(shouldShowAction) {
        return shouldShowAction(transaction) ? <DeleteForeverIcon onClick={() => action(transaction.id)} /> : null
      } else {
        return <DeleteForeverIcon onClick={() => action(transaction.id)} />;
      }
    }
    return { data: {bank: transaction.bank, amount: `€ ${transaction.amount}`, date: formatMonthDayYear(+transaction.date), actions: getAction() }}})
}

const titleConfig = { 
  [PaymentTypeEnum.CUSTOMER]: 'Customer payment',
  [PaymentTypeEnum.PARTNER]: 'Agency payment'
}

interface Props {
  payment: Payment;
  title: string;
  subtitle?: string;
  handleDeletePayment: (transactionId: string) => void;
  handleAddPayment: (transaction: Omit<Transaction, 'type'>) => void;
  handleAddNote: (notes) => void;
  shouldShowAction?: (transaction: Omit<Transaction, 'type'>) => boolean;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  paymentTitle: {
    fontWeight: 700,
    fontSize: '1rem',
  },
  expandedContainer: {
    marginTop: '0.5rem'
  },
  row: {
    marginBottom: '0.25rem'
  },
  detailsTitle: {
    fontWeight: 'bold',
    marginRight: '0.5rem'
  }
}));

const PaymentDetails: FC<Props> = ({ payment, title, subtitle, handleAddNote, handleDeletePayment, handleAddPayment, shouldShowAction }) => {
  const { detailsTitle } = useStyles();
  const [notes, setNotes] = useState<string>("");
  const [openPaymentForm, setOpenPaymentForm] = useState<boolean>();

  useEffect(() => {
    console.log(payment.notes)
    setNotes(payment.notes || "")
  }, [])

  const renderDetailsTitle = (title: string) => <span className={detailsTitle}>{title}</span>;

  const renderSection = (title: string, type: 'deposit' | 'total') => {
    return (
      <Grid item xs={6}>
        <h3>{title}</h3>
        <div>{renderDetailsTitle('Amount:')} {formatMoney(payment[type].amount)}</div>
        <div>{renderDetailsTitle('Remaining:')}  {formatMoney(payment[type].remaining)}</div>
        <div>{renderDetailsTitle('Deadline:')} {formatMonthDayYear(+payment[type].date)}</div>
      </Grid>
    )
  }

  const renderSubtitle = () => {
    return subtitle ? ` - ${subtitle}` : '';
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div>
          <h2>{title} {renderSubtitle()}</h2>
        </div>
        {payment.total.remaining !== 0 && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpenPaymentForm(true)}
          >
            Add payment
          </Button>
        )}
      </div>
      {openPaymentForm && (
        <PaymentForm remaining={payment.total.remaining} onComplete={() => setOpenPaymentForm(false)} handleAddPayment={handleAddPayment} />
      )}
      <Grid container spacing={3}>
        {renderSection('Deposit', 'deposit')}
        {renderSection('Total', 'total')}
      </Grid>
      
      <h3>Transactions</h3>
      {payment.payments.length > 0 && (
        <div style={{ paddingBottom: '1rem' }}>
          <Table tableData={createTableData(payment.payments.filter((payment) => !!payment.amount), handleDeletePayment, shouldShowAction)} size="small" />
        </div>
      )}
      {payment.payments.length <= 0 && (
        <div style={{ paddingBottom: '1rem' }}>
          There are no transactions.
        </div>
      )}
      <div>
        <TextField
          id="outlined-multiline-static"
          variant='outlined'
          label="Payment notes"
          style={{ width: "100%" }}
          onChange={(event) => {
            setNotes(event.target.value)
          }}
          multiline
          value={notes}
          defaultValue=""
        />
        <Button style={{ marginTop: '1rem', marginBottom: '1rem' }} variant="contained" onClick={() => handleAddNote(notes)}>Save note</Button>
        </div>
    </>
  );
};

export default PaymentDetails;

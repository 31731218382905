import gql from "graphql-tag";

export const DELETE_HOTEL = gql`
  mutation deleteHotel($id: String!) {
    deleteHotel(id: $id)
  }
`

export const UPDATE_HOTEL = gql`
  mutation updateHotel($id: String!, $values: HotelInput) {
    updateHotel(id: $id, values: $values)
  }
`

export const CREATE_HOTEL = gql`
  mutation createHotel($values: HotelInput) {
    createHotel(values: $values) {
      id
      name
    }
  }
`;
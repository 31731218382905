import { gql } from "apollo-boost";

export const GET_VALID_GIFT_CARD = gql`
  query getValidGiftCard($code: String!) {
    getValidGiftCard(code: $code) {
      id
      code
      amount
    }
  }
`;

export const GET_USED_GIFT_CARD = gql`
  query getUsedGiftCard($id: String!, $productType: String!) {
    getUsedGiftCard(id: $id, productType: $productType) {
      id
      code
      amount
    }
  }
`;
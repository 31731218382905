import { gql } from "apollo-boost";

export const APPLY_GIFT_CARD = gql`
  mutation applyGiftCard($code: String!, $productType: String!, $id: String!) {
    applyGiftCard(code: $code, productType: $productType, id: $id)
  }
`;

export const REMOVE_GIFT_CARD = gql`
  mutation cleanGiftCard($code: String!, $productType: String!, $id: String!) {
    cleanGiftCard(code: $code, productType: $productType, id: $id) 
  }
`
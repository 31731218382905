import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { GET_ADMINS } from './graphql/queries';
import QueryGuard from '../../../Components/QueryGuard';
import { Avatar, Card, CardHeader, Fab, IconButton, makeStyles } from '@material-ui/core';
import { DELETE_ADMIN } from './graphql/mutations';
import { useUserDetails } from '../../../UserContext/UserContex';
import DeleteIcon from '@material-ui/icons/Delete';
import { RouteComponentProps, withRouter } from 'react-router';
import { Admin } from './types';
import { red } from '@material-ui/core/colors';
import { getInitials } from '../../../utils/utils';

interface Props extends RouteComponentProps {

}

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
  },
}));

const Admins: FC<Props> = ({ history }) => {
  const { id } = useUserDetails();
  const { fab } = useStyles()
  const { data, loading, error, refetch } = useQuery(GET_ADMINS)
  const [deleteAdmin] = useMutation(DELETE_ADMIN, { onCompleted: () => {
    refetch()
  }});
  const handleDelete = async (email: string) => {
    await deleteAdmin({
      variables: { adminId: id, email }
    })
  }

  const renderAction = (admin: Admin) => {
    if(id === admin.id) {
      return null;
    }

    return (
      <IconButton onClick={() => handleDelete(admin.email)}><DeleteIcon /></IconButton>
    )
  }

  return (
    <>
      <h1>Admins</h1>
      <Fab 
        color="primary"
        onClick={() => history.push("/operations/admins/new")}
        aria-label="add"
        className={fab}
      >
        <AddIcon />
      </Fab>
    <QueryGuard data={data} loading={loading} error={!!error}>
      {data && data.admins && data.admins.map((admin: Admin) => (
        <Card style={{marginBottom: '8px'}}>
          <CardHeader 
            avatar={
              <Avatar style={{ backgroundColor: red[500] }}>
                {getInitials(admin.firstName, admin.lastName)}
              </Avatar>
            } 
            title={`${admin.firstName} ${admin.lastName}`} 
            subheader={admin.email}
            action={renderAction(admin)} 
          />
        </Card>
      ))}
    </QueryGuard>
    </>
  )
};

export default withRouter(Admins);
import React from 'react';
import { Formik, FormikProps, Form } from 'formik';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import { Grid, TextField, Button, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { TicketInput } from '../types';
import moment from 'moment';

export type TicketFormType = Omit<TicketInput, 'customerId'>

interface TicketFormProps {
  initialValues?: TicketFormType;
  onSubmit: (value: TicketFormType) => void
}

const ticketSchema = Yup.object().shape({
  company: Yup.string()
    .required('Required'),
  numberOfPassengers: Yup.number().positive('Must be a positive number').required('Required'),
  date: Yup.string()
  .required('Required'),
});

const defaultValues: Omit<TicketInput, 'customerId'> = {
  company: '',
  partner: '',
  partnerTotal: 0,
  customerTotal: 0,
  numberOfPassengers: 0,
  date: moment().format('YYYY-MM-DD'),
}

const useStyles = makeStyles(theme => ({
  input: {
    width: '80%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
  }
}));

const TicketForm = ({ onSubmit, initialValues = defaultValues }: TicketFormProps) => {
  const { form, input, button } = useStyles();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ticketSchema}
    >
      {({ errors, touched, handleChange, handleBlur, values, setFieldValue }: FormikProps<TicketFormType>) => (
        <Form className={form}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Company/Airline"
                name="company"
                value={values.company}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.company && !!touched.company)}
                helperText={(!!errors.company && !!touched.company) && errors.company}
                margin="normal"
                className={input}
              />
            </Grid>
            <Grid item xs={6}>
                <KeyboardDatePicker
                  format="dd/MM/yyyy"
                  onFocus={() => {}}
                  margin="normal"
                  id="date-picker-inline"
                  label="Departure date"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  name="date"
                  inputVariant="outlined"
                  value={values.date}
                  onChange={(date) => setFieldValue('date', date)}
                  onBlur={handleBlur}
                  error={(!!errors.date && !!touched.date)}
                  helperText={(!!errors.date && !!touched.date) && errors.date}
                  className={input}
                />
              </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Number of passengers"
                name="numberOfPassengers"
                value={values.numberOfPassengers}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.numberOfPassengers && !!touched.numberOfPassengers)}
                helperText={(!!errors.numberOfPassengers && !!touched.numberOfPassengers) && errors.numberOfPassengers}
                margin="normal"
                className={input}
                type='number'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Total"
                name="customerTotal"
                value={values.customerTotal}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.customerTotal && !!touched.customerTotal)}
                helperText={(!!errors.customerTotal && !!touched.customerTotal) && errors.customerTotal}
                margin="normal"
                className={input}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Partner agency"
                name="partner"
                value={values.partner}
                multiline={true}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                margin="normal"
                className={input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Partner agency total"
                name="partnerTotal"
                value={values.partnerTotal}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.partnerTotal && !!touched.partnerTotal)}
                helperText={(!!errors.partnerTotal && !!touched.partnerTotal) && errors.partnerTotal}
                margin="normal"
                className={input}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" className={button} type="submit" color="primary">
                Create ticket
              </Button>
            </Grid>
          </Grid>
          </MuiPickersUtilsProvider>

        </Form>
      )}
    </Formik>
  )
}

export default TicketForm;
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import EmailIcon from '@material-ui/icons/Email';
import HotelIcon from '@material-ui/icons/Hotel';
import DashboardIcon from '@material-ui/icons/Dashboard';
import WorkIcon from '@material-ui/icons/Work';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MarkunreadMailbox from '@material-ui/icons/MarkunreadMailbox'
import SettingsIcon from '@material-ui/icons/Settings';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import BarChartIcon from '@material-ui/icons/BarChart';

export const mainList = [
  {
    title: "Notifications",
    icon: <DashboardIcon />,
    route: '/',
  },
  {
    title: "Requests",
    icon: <MarkunreadMailbox />,
    route: '/requests',
  },
  {
    title: "Bookings",
    icon: <WorkIcon />,
    route: '/bookings',
  },
  {
    title: "Customers",
    icon: <PeopleIcon />,
    route: '/customers',
  },
  {
    title: "Hotels",
    icon: <HotelIcon />,
    route: '/hotels',
  },
  {
    title: 'Gift vouchers',
    icon: <CardGiftcardIcon />,
    route: '/gift-vouchers'
  },
  {
    title: "Receipt",
    icon: <ReceiptIcon />,
    route: '/receipts',
  },
  {
    title: "Message statuses",
    icon: <EmailIcon />,
    route: '/messages',
  },
  {
    title: "Operation settings",
    icon: <SettingsIcon />,
    route: '/operations',
  },
  {
    title: "Analytics",
    icon: <BarChartIcon />,
    route: '/analytics',
  }
]

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
import { createContext, useContext } from 'react';

interface Props {
  isAuthenticated: boolean;
  login: (accessToken: string) => void;
  logout: () => void
};

const defaultValue = {
  isAuthenticated: false,
  login: (accessToken: string) => {},
  logout: () => {}
}

const AuthContext = createContext<Props>(defaultValue);

const useAuth = () => useContext(AuthContext);

export { useAuth };

export default AuthContext;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ACTIVE_REQUESTS, REQUEST } from '../graphql/queries';
import { EDIT_REQUEST } from '../graphql/mutations';
import useNotifications from '../../../Notifications/useNotifications';
import useHandleAuthError from '../../../utils/hooks/useHandleAuthError';
import { RequestFormType } from '../RequestForm/types';
import RequestForm from '../RequestForm/RequestForm';

interface Props extends RouteComponentProps<{ id: string }> {
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

const EditRequest = ({ match, history }: Props) => {
  const onError = useHandleAuthError();
  const { addSuccess, addError } = useNotifications();

  const id = match.params.id;

  const { loading, data } = useQuery(REQUEST, {
    variables: { id },
    onError
  });

  const [updateRequest] = useMutation(EDIT_REQUEST, 
    { 
      onCompleted: () => {
          addSuccess('Request updated successfully.')
          history.push(`/requests`)
          
      },
      onError: () => addError('Error updating this request. Please try again.'),
      refetchQueries: [{ query: REQUEST, variables: { id } }, { query: ACTIVE_REQUESTS }],
      awaitRefetchQueries: true,
    }
  );


  const onSubmit = (values: RequestFormType
    ) => updateRequest({ 
    variables: { 
      id,
      input : {
        name: values.name,
        phone: values.phone,
        email: values.email,
        notes: values.notes
      }
    }
  });

  if (loading) {
    return <div>Loading</div>
  }
  
  if (data && data.request) {
    return (
      <>
        <h1>Edit request</h1>
        <RequestForm onSubmit={onSubmit} initialValues={data.request} />
      </>
    )
  }
  
  return <div>{null}</div>
}

export default withRouter(EditRequest);
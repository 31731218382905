import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  grid: {
    textAlign: 'right',
  },
}));

interface PageHeaderProps {
  title: string;
  buttons?: React.ReactNode;
  className?: string;
}

const PageHeader = ({ title, buttons, className }: PageHeaderProps) => {
  const { grid } = useStyles();

  const Title = () => (
    <Typography className={className} variant="h5" component="h5">
      {title}
    </Typography> 
  );

  if (!buttons) {
    return ( 
      <Title />
    );
  };

  return (
    <Grid className={className} container>
      <Grid item xs={6}>
        <Title />
      </Grid>
      <Grid className={grid} item xs={6}>
        {buttons}
      </Grid>
    </Grid>
  );
}

export default PageHeader;


import React, { FC, useState } from 'react';
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';
import PeopleIcon from '@material-ui/icons/People';
import { PaymentTypeEnum, Transaction } from '../../Customers/types/types';
import { formatMonthDayYear } from '../../../utils/utils';
import { makeStyles, Divider, IconButton, Modal, Backdrop, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { RouteComponentProps, withRouter } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import useNotifications from '../../../Notifications/useNotifications';
import { GET_ACTION_ITEMS, GET_CUSTOMER, GET_CUSTOMER_TICKETS } from '../../Customers/graphql/queries';
import QueryGuard from '../../../Components/QueryGuard';
import PaymentDetails from '../../Bookings/BookingDetails/Payment/PaymentDetails';
import { GET_TICKET } from '../graphql/queries';
import { Ticket } from '../types';
import { ADD_TICKET_PAYMENT, DELETE_TICKET, DELETE_TICKET_PAYMENT, UPDATE_TICKET_PAYMENT_NOTES } from '../graphql/mutations';
import ApplyGiftCardSection from '../../../Components/ApplyGiftCardSection/ApplyGiftCardSection';

interface Props extends RouteComponentProps<{ id: string }> {
}

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: '1rem',
  },
  icon: {
    width: '1.25rem',
    height: '1.25rem',
    marginRight: '0.5rem'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    fontSize: '1.15rem'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    marginTop: '2rem'
  },
  modal: {
    display: 'flex',
    paddingTop: '10rem',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    textAlign: 'center',
    height: '25%',
    width: '50%',
    padding: theme.spacing(2, 4, 3),
  },
}));

const TicketDetails: FC<Props> = ({ match, history }) => {
  const { id } = match.params;
  const { data, error, loading } = useQuery(GET_TICKET, { variables: { id }})
  const { icon, row, header, modal, modalPaper } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { addSuccess, addError } = useNotifications();
  const [deleteTicket] = useMutation(DELETE_TICKET, {
    onCompleted: ({ deleteTicket }) => {
      if(!!deleteTicket) {
        addSuccess('Request cancelled successfully');
        setIsModalOpen(false);
        history.push(`/customers/details/${data.ticket.customer.id}`);
      } else {
        addError('Error cancelling request. Please try again.')
      }
    },
    refetchQueries: [{ query: GET_CUSTOMER }]
  });


  const [deletePayment] = useMutation(DELETE_TICKET_PAYMENT, {
    onCompleted: () => addSuccess('Payment removed'),
    onError: () => addError('There was an error removing this payment'),
    refetchQueries: [{ query: GET_TICKET, variables: { id } }]
  });

  const [addNotes] = useMutation(UPDATE_TICKET_PAYMENT_NOTES, {
    onCompleted: () => addSuccess('Note has been added'),
    onError: () => addError('There was an error adding notes to this payment'),
    refetchQueries: [{ query: GET_TICKET, variables: { id } }]
  });

  const [makePayment] = useMutation(ADD_TICKET_PAYMENT, {
    onCompleted: ({ makeTicketPayment }) => {
      if (!!makeTicketPayment) {
        addSuccess('Payment fields updated successfully');
      } else {
        addError('Error updating payment fields. Please try again.')
      } 
    },
    refetchQueries: [{ query: GET_ACTION_ITEMS }, { query: GET_TICKET, variables: { id } }],
    awaitRefetchQueries: true,
  });

  const handleUpdateNotes = async (notes: string, paymentType: PaymentTypeEnum) => {
    await addNotes({ variables: { ticketId: id, paymentType, notes }})
  }

  const handleAddPayment = async (transaction: Transaction, paymentType: PaymentTypeEnum) => {
    await makePayment({ variables: { ticketId: id, transaction, paymentType }})
  }

  const handleDeletePayment = async (transactionId: string, paymentType: PaymentTypeEnum) => {
    await deletePayment({ variables: { ticketId: id, transactionId, paymentType }});
  }


  const handleDeleteTicket = () => deleteTicket({
    variables: { id },
    refetchQueries: [{ query: GET_ACTION_ITEMS }, { query: GET_CUSTOMER, variables: { id: data.ticket.customer.id } }, { query: GET_CUSTOMER_TICKETS, variables: { customerId: data.ticket.customer.id } }], 
    awaitRefetchQueries: true,
  });

  const renderTicketDetails = () => {
    const ticket: Ticket = data.ticket;
    console.log(ticket);
    return (
  <>
    <div className={header}>
      <div>
        <div className={row}>Company/Airline: {ticket.company ? ticket.company : "-"}</div>
        <div className={row}>
          <FlightTakeoff className={icon} />
          <div>{formatMonthDayYear(+ticket.date)}</div>
          <PeopleIcon className={icon} style={{ marginLeft: '2rem' }} />
          <span>{`${ticket.numberOfPassengers} x Passengers`}</span>
        </div>
        <div className={row}>Customer name: {ticket.customer ? `${ticket.customer.lastName} ${ticket.customer.firstName}`.toUpperCase() : null}</div>
      </div>
      <div>
        <IconButton onClick={() => setIsModalOpen(true)} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
    <Divider />
    <ApplyGiftCardSection productType='ticket' entityId={id} />
    {ticket.customerPayment && ticket.customerPayment.total.amount ? <PaymentDetails shouldShowAction={(transaction) => !transaction.code} payment={ticket.customerPayment} title="Customer payments" handleAddNote={(notes) => handleUpdateNotes(notes, PaymentTypeEnum.CUSTOMER)} handleAddPayment={(newTransaction) => handleAddPayment({ ...newTransaction, type: PaymentTypeEnum.CUSTOMER }, PaymentTypeEnum.CUSTOMER) } handleDeletePayment={(transactionId) => handleDeletePayment(transactionId, PaymentTypeEnum.CUSTOMER)}  /> : null}
    <Divider />
    {ticket.partnerPayment && ticket.partnerPayment.total.amount ? (
      <PaymentDetails payment={ticket.partnerPayment} subtitle={ticket.partnerPayment.name} title="Agency payments" handleAddNote={(notes) => handleUpdateNotes(notes, PaymentTypeEnum.PARTNER)} handleAddPayment={(newTransaction) => handleAddPayment({ ...newTransaction, type: PaymentTypeEnum.PARTNER }, PaymentTypeEnum.PARTNER) } handleDeletePayment={(transactionId) => handleDeletePayment(transactionId, PaymentTypeEnum.PARTNER)}  />
    ) : null}
  </>
  )}

  return (
    <>
      <QueryGuard loading={loading} error={!!error} data={data && data.ticket}>
        {data && data.ticket && renderTicketDetails()}  
      </QueryGuard>
      <Modal
          className={modal}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className={modalPaper}>
            <h2>Are you sure you want to delete this request?</h2>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
              <Button color="primary" onClick={handleDeleteTicket} variant='contained'>Delete</Button>
              <Button onClick={() => setIsModalOpen(false)} color="secondary">Cancel</Button>
            </div>
          </div>
        </Modal>
    </>
  );
};

export default withRouter(TicketDetails);
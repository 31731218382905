import gql from "graphql-tag";

export const CREATE_INVOICE = gql`
  mutation createInvoice($input: InvoiceInput) {
    createInvoice(input: $input)
  }
`;

export const DELETE_INVOICE = gql`
  mutation deleteInvoice($id: ID!) {
    deleteInvoice(id: $id)
  }
`

export const UPDATE_INVOICE = gql`
  mutation editInvoice($id: ID!, $values: InvoiceInput) {
    editInvoice(id: $id, values: $values)
  }
`
import React, { FC } from 'react';
import { Booking } from '../../../Customers/types/types';
import SendIcon from '@material-ui/icons/Send';
import DownloadIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_DOCUMENT } from '../../../Customers/graphql/mutations';
import useNotifications from '../../../../Notifications/useNotifications';
import { PaperworkType, PaperworkAction } from '../../types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

interface Props {
  booking: Booking;
  type: PaperworkType;
  onViewClick?: () => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  paperworkTitle: {
    fontWeight: 700,
    fontSize: '1rem'
  },
  icon: {
    marginLeft: '1rem'
  }
}));

const title = {
  confirmation: 'Confirmation',
  voucher: 'Voucher',
  invoice: 'Invoice/Receipt',
  feedback: 'Feedback'
}

const Paperwork: FC<Props> = ({ type, booking, onViewClick }) => {
  const { container, paperworkTitle, icon } = useStyles();
  const { addSuccess, addError } = useNotifications();
  const [createDocument, { error }] = useMutation(CREATE_DOCUMENT, {
    onError: () => addError('Something went wrong. Please try again.')
  });

  const sendDocument = async (type: PaperworkType, action: PaperworkAction) => {
    const result: any = await createDocument({ variables: { type, id: booking.id, action }});

    if (action === "download") {
      if (result.data && result.data.createDocument) {
        const myWindow = window.open("", "document");
        if (myWindow) myWindow.document.write(result.data.createDocument);
      }
    }

    if (action === "send") {
      if (!error) {
        addSuccess('Document sent.');
      }
    }
  }
  return (
    <div className={container}>
      <div className={paperworkTitle}>
        {title[type]}
      </div>
      <div>
        {type === "invoice" && (
          <Button
            variant="outlined"
            color="secondary"
            className={icon}
            startIcon={<EditIcon />}
            onClick={onViewClick}
          >
            Edit
          </Button>
        )} 
        <Button
          variant="contained"
          color="default"
          disabled={type === 'feedback'}
          className={icon}
          startIcon={<DownloadIcon />}
          onClick={() => sendDocument(type, "download")}
        >
          Download
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={icon}
          endIcon={ <SendIcon />}
          onClick={() => sendDocument(type, "send")}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default Paperwork;
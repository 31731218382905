import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import React, { FC, useState } from 'react';
import { GET_USED_GIFT_CARD, GET_VALID_GIFT_CARD } from './graphql/queries';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button,IconButton, Modal, TextField, makeStyles } from '@material-ui/core';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import GiftCard from './components/GiftCard/GiftCard';
import { APPLY_GIFT_CARD, REMOVE_GIFT_CARD } from './graphql/mutations';
import { GET_TICKET } from '../../Pages/Tickets/graphql/queries';
import { GET_BOOKING } from '../../Pages/Bookings/graphql/queries';

interface Props {
  productType: "booking" | "ticket";
  entityId: string;
}

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    paddingTop: '10rem',
    justifyContent: 'center',
  }
}));

const ApplyGiftCardSection: FC<Props> = ({ entityId, productType}) => {
  const [code, setCode] = useState<string>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { data: giftCardData, loading: giftCardLoading } = useQuery(GET_USED_GIFT_CARD, { variables: { id: entityId, productType }})
  const existingGiftCard = giftCardData && giftCardData.getUsedGiftCard;
  const exisitingGiftCardCode = existingGiftCard ? existingGiftCard.code : "";

  const productRefetch = {
    ticket: GET_TICKET,
    booking: GET_BOOKING
  }

  const refetchQueries = [
    { query: productRefetch[productType], variables: { id: entityId }}, 
    { query: GET_USED_GIFT_CARD, variables: { id: entityId, productType }}
  ]

  const [removeGiftCard] = useMutation(REMOVE_GIFT_CARD, { 
    variables: { productType, id: entityId, code: exisitingGiftCardCode }, 
    refetchQueries
  })

  const [fetchGiftCard, { data, loading, called }] = useLazyQuery(GET_VALID_GIFT_CARD);
  
  const [applyGiftCard] = useMutation(APPLY_GIFT_CARD, { refetchQueries })

  const handleClose = () => setOpenModal(false)

  const onSearch = () => {
    fetchGiftCard({ variables: { code } })
  }

  const handleApplyGiftCard = async () => {
    const result = await applyGiftCard({ variables: { productType, id: entityId, code }});
    handleClose()
    console.log({result})
  }

  const handleRemove = async () => {
    await removeGiftCard();
  }

  const searchResult = data && data.getValidGiftCard;



  const renderNoResultMessage = () => {
    if(called && !searchResult) {
      return <div style={{ color: "grey", marginBottom: "0.5rem" }}>{`There is no valid gift voucher with a code ${code}. Please check if it is paid or not assigned to a different product.`}</div>
    }
  }

  const renderGiftCardSection = () => {
    if(giftCardLoading) {
      return "Loading..."
    }

    if(existingGiftCard) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "0.5rem" }}>{`Applied code: ${existingGiftCard.code}`}</div>
          <IconButton onClick={handleRemove} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </div>
      )
    }

    if(openModal) { 
      return null
    }

    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<CardGiftcardIcon />}
        onClick={() => setOpenModal(true)}
      >
        Apply
      </Button>
    )
  }
  
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>Gift voucher</h2>
        {renderGiftCardSection()}
      </div>
    
          {openModal && <>
              <TextField label="Search by code" variant='outlined' value={code} onChange={(e) => setCode(e.target.value)} style={{ marginBottom: '1rem', width: '100%' }} />
              {renderNoResultMessage()}
              <div style={{ marginBottom: '0.5rem'}}>
                <Button style={{ marginRight: "0.5rem"}} color="primary" disabled={code ? !code.length : true} onClick={onSearch} variant='contained'>Search</Button>
                <Button color="default" onClick={handleClose} variant='contained'>Cancel</Button>
              </div>
            
            {searchResult ? <GiftCard code={searchResult.code} amount={searchResult.amount} actions={[{ label: "Apply", onClick: handleApplyGiftCard }]} /> : null}
          </>}
    </>
  )
};

export default ApplyGiftCardSection;
import { useAuth } from "../../AuthContext/AuthContext"
import useNotifications from "../../Notifications/useNotifications";

const useHandleAuthError = () => {
  const { logout } = useAuth();
  const { addError } = useNotifications();

  const onError = (error) => {
    if (error && error.message.includes('Unauthenticated')) {
      logout();
      addError('Authentication failed')
    } else {
      addError('There was an error completing your request.')
    }
  }

  return onError;
} 

export default useHandleAuthError;
import React from "react";
import AppContainer from "./AppContainer";
import { useAuth } from "./AuthContext/AuthContext";
import { BrowserRouter as Router } from 'react-router-dom';
import Login from "./Pages/Login/Login";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  overrides: {
    MuiTableRow: {
      hover: {
         backgroundColor: '#f5f5f5',
      }
    }
  }
});

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {isAuthenticated ? <AppContainer /> : <Login />}
      </Router>
    </ThemeProvider>
  );
};

export default App;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import HotelForm from '../HotelForm';
import { withRouter, RouteComponentProps } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_HOTEL, GET_HOTELS } from '../graphql/queries';
import { UPDATE_HOTEL } from '../graphql/mutations';
import useNotifications from '../../../Notifications/useNotifications';
import useHandleAuthError from '../../../utils/hooks/useHandleAuthError';
import { HotelInput } from '../HotelForm/types';

interface Props extends RouteComponentProps<{ id: string }> {
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

const EditHotel = ({ match, history }: Props) => {
  const onError = useHandleAuthError();
  const { addSuccess, addError } = useNotifications();

  const id = match.params.id;

  const { loading, data } = useQuery(GET_HOTEL, {
    variables: { id },
    onError
  });

  const [updateHotel] = useMutation(UPDATE_HOTEL, 
    { 
      onCompleted: () => {
          addSuccess('Hotel updated successfully.')
          history.push(`/hotels/details/${id}`)
          
      },
      onError: () => addError('Error updating hotel. Please try again.'),
      refetchQueries: [{ query: GET_HOTEL, variables: { id } }, { query: GET_HOTELS }],
      awaitRefetchQueries: true,
    }
  );


  const onSubmit = (values: HotelInput) => updateHotel({ 
    variables: { 
      id,
      values : {
        name: values.name,
        phone: values.phone,
        address: values.address,
      }
    }
  });

  if (loading) {
    return <div>Loading</div>
  }
  
  if (data && data.hotel) {
    return (
      <>
        <h1>Edit hotel</h1>
        <HotelForm onSubmit={onSubmit} initialValues={data.hotel} />
      </>
    )
  }
  
  return <div>{null}</div>
}

export default withRouter(EditHotel);
import { Slide, toast, ToastContainer } from 'react-toastify';
import React from 'react';

import NotificationContext from './NotificationContext';

import 'react-toastify/dist/ReactToastify.css';

interface NotificationProviderProps {
  children: React.ReactNode;
}

const defaultMessage = 'Something went wrong. Please try again later.';

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const addSuccess = (message: string) => toast.success(message);
  const addError = (message: string = defaultMessage) => toast.error(message);

  return (
    <NotificationContext.Provider value={{ addSuccess, addError }}>
      {children}
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        transition={Slide}
      />
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

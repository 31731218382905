import { Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import * as React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert'

interface Props {
  items: { label: string; onClick: () => void }[]
}

const ContextMenu: React.FC<Props> = ({ items }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map(({ label, onClick }, index) => {
          const handleClick = (e: React.SyntheticEvent) => {
            e.stopPropagation()
            handleClose()
            onClick()
          }

          return <MenuItem key={index} onClick={handleClick}>{label}</MenuItem>
        })}
      </Menu>
    </div>
  );
}

export default ContextMenu
import React, { FC } from 'react';
import { Formik, FormikProps, Form } from 'formik';
import { Grid, TextField, Button, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';

export type AdminLoginInput = {
  email: string;
  password: string;
}

interface Props {
  onSubmit: (values: AdminLoginInput) => void
}

const adminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .required('Required'),
});

const defaultValues: AdminLoginInput = {
  email: '',
  password: ''
}

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
  }
}));

const LoginForm: FC<Props> = ({ onSubmit }) => {
  const { form, input, button } = useStyles();

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={adminLoginSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, handleChange, handleBlur, values }: FormikProps<AdminLoginInput>) => (
        <Form className={form}>
          <TextField
            id="outlined-name"
            label="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(!!errors.email && !!touched.email)}
            helperText={(!!errors.email && !!touched.email) && errors.email}
            margin="normal"
            className={input}
          />
          <TextField
            id="outlined-name"
            label="Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(!!errors.password && !!touched.password)}
            helperText={(!!errors.password && !!touched.password) && errors.password}
            margin="normal"
            type="password"
            className={input}
          />
          <Button variant="contained" className={button} type="submit" color="primary">
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm;
import React, { FC, useState } from 'react';
import { startCase } from 'lodash';
import Calendar from '@material-ui/icons/CalendarToday';
import PeopleIcon from '@material-ui/icons/People';
import HotelIcon from '@material-ui/icons/Hotel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import ExtensionIcon from '@material-ui/icons/Extension';
import { Booking, PaymentType, PaymentTypeEnum, Transaction } from '../../Customers/types/types';
import { formatMonthDayYear } from '../../../utils/utils';
import { makeStyles, Divider, IconButton, Modal, Backdrop, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PaymentDetails from './Payment/PaymentDetails';
import Paperwork from './Paperwork';
import { RouteComponentProps, withRouter } from 'react-router';
import { GET_BOOKING } from '../graphql/queries';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { DELETE_BOOKING, MAKE_PAYMENT } from '../../Customers/graphql/mutations';
import useNotifications from '../../../Notifications/useNotifications';
import { GET_ACTION_ITEMS, GET_CUSTOMER } from '../../Customers/graphql/queries';
import QueryGuard from '../../../Components/QueryGuard';
import moment from 'moment';
import { ADD_PAYMENT_NOTES, DELETE_PAYMENT } from '../graphql/mutations';
import ApplyGiftCardSection from '../../../Components/ApplyGiftCardSection/ApplyGiftCardSection';

interface Props extends RouteComponentProps<{ id: string }> {
}

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: '1rem',
  },
  icon: {
    width: '1.25rem',
    height: '1.25rem',
    marginRight: '0.5rem'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    fontSize: '1.15rem'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between'
  },
  modal: {
    display: 'flex',
    paddingTop: '10rem',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    textAlign: 'center',
    height: '25%',
    width: '50%',
    padding: theme.spacing(2, 4, 3),
  },
}));

const BookingDetails: FC<Props> = ({ match, history }) => {
  const { id } = match.params;
  const { data, error, loading } = useQuery(GET_BOOKING, { variables: { id }})
  const displayGuestType = (type: number) => type !== 0;
  const {title, icon, row, header, modal, modalPaper, center } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { addSuccess, addError } = useNotifications();
  const [deleteBooking] = useMutation(DELETE_BOOKING, {
    onCompleted: ({ deleteBooking }) => {
      if(!!deleteBooking) {
        addSuccess('Request cancelled successfully');
        setIsModalOpen(false);
        history.push('/bookings');
      } else {
        addError('Error cancelling request. Please try again.')
      }
    },
    refetchQueries: [{ query: GET_CUSTOMER }]
  });

  const [deletePayment] = useMutation(DELETE_PAYMENT, {
    onCompleted: () => addSuccess('Payment removed'),
    onError: () => addError('There was an error removing this payment'),
    refetchQueries: [{ query: GET_BOOKING, variables: { id } }]
  });

  const [addNotes] = useMutation(ADD_PAYMENT_NOTES, {
    onCompleted: () => addSuccess('Note has been added'),
    onError: () => addError('There was an error adding notes to this payment'),
    refetchQueries: [{ query: GET_BOOKING, variables: { id } }]
  });

  const [makePayment] = useMutation(MAKE_PAYMENT, {
    onCompleted: ({ makePayment }) => {
      if (!!makePayment) {
        addSuccess('Payment fields updated successfully');
      } else {
        addError('Error updating payment fields. Please try again.')
      } 
    },
    refetchQueries: [{ query: GET_ACTION_ITEMS }, { query: GET_BOOKING, variables: { id } }],
    awaitRefetchQueries: true,
  });

  const handleAddPayment = async (transaction: Transaction, paymentType: PaymentTypeEnum) => {
    await makePayment({ variables: { bookingId: id, transaction, paymentType }})
  }

  const handleAddNotes = async (notes: string, paymentType: PaymentTypeEnum) => {
    await addNotes({ variables: { id, paymentType, notes }});
  }

  const handleDeletePayment = async (transactionId: string, paymentType: PaymentTypeEnum) => {
    await deletePayment({ variables: { bookingId: id, transactionId, paymentType }});
  }

  const cancelBooking = () => deleteBooking({
    variables: { bookingId: id },
    refetchQueries: [{ query: GET_ACTION_ITEMS }, { query: GET_CUSTOMER, variables: { id: data.booking.customerId } }], 
    awaitRefetchQueries: true,
  });

  const renderBookingDetails = () => {
    const booking: Booking = data.booking;
    console.log(booking);
    return (
  <>
    <div className={header}>
      <div>
        <h1 className={title}>{booking.hotel ? booking.hotel.name : booking.packageName} - {`${booking.customer.lastName} ${booking.customer.firstName}`.toUpperCase()}</h1>
        <div className={row}>
          <Calendar className={icon} />
          <div>{formatMonthDayYear(+booking.checkIn)} - {formatMonthDayYear(+booking.checkOut)}</div>
          <PeopleIcon className={icon} style={{ marginLeft: '2rem' }} />
          <span>{`${booking.adults} x Adults`} {displayGuestType(booking.children) && `${booking.children} x Children ${booking.childrenAge ? `(${booking.childrenAge})` : ''}`} {displayGuestType(booking.infants) && `${booking.infants} x Infants`}</span>
        </div>
        <div className={row}>
          {booking.roomType && (
            <div className={center} style={{ marginRight: '2rem' }}>
              <HotelIcon className={icon} />
              {startCase(booking.roomType)}
            </div>
          )}
          {booking.extra && (
            <div className={center} style={{ marginRight: '2rem' }}>
              <ExtensionIcon className={icon} />
              {`Extra ${booking.extra}`}
            </div>
          )}
          {booking.board && (
            <div className={center} style={{ marginRight: '2rem' }}>
              <FastfoodIcon className={icon} />
              {startCase(booking.board)}
            </div>
          )}
          {booking.view && (
            <div className={center}>
              <VisibilityIcon className={icon} />
              {startCase(booking.view)}
            </div>
          )}
        </div>
        {booking.createdBy && (
          <div style={{ marginBottom: '1rem' }}><span style={{ fontWeight: 'bold' }}>Created by:</span> {booking.createdBy}</div>
        )}
                {booking.reservationDate && (
          <div style={{ marginBottom: '1rem' }}><span style={{ fontWeight: 'bold' }}>Created at:</span> {formatMonthDayYear(booking.reservationDate)}</div>
        )}
      </div>
      <div>
        <Link to={{ state: { booking }, pathname: `/bookings/edit/${id}` }}>
          <IconButton aria-label="edit">
            <EditIcon />
          </IconButton>
        </Link>
        <IconButton onClick={() => setIsModalOpen(true)} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
    <Divider />
    {booking.note && (
      <>
        <h2 className={title}>Notes</h2>
        {booking.note}
      </>
    )}
    <ApplyGiftCardSection productType='booking' entityId={id} />
    <PaymentDetails title="Customer payment" payment={booking.customerPayment} shouldShowAction={(transaction) => !transaction.code} handleAddNote={(notes) => handleAddNotes(notes, PaymentTypeEnum.CUSTOMER )} handleAddPayment={(transaction) => handleAddPayment({ ...transaction, type: PaymentTypeEnum.CUSTOMER }, PaymentTypeEnum.CUSTOMER)} handleDeletePayment={(transactionId) => handleDeletePayment(transactionId, PaymentTypeEnum.CUSTOMER)} />
    <Divider />
    {booking.partnerPayment.total.amount ? (
      <PaymentDetails title="Agency payment" subtitle={booking.partnerPayment.name} payment={booking.partnerPayment} handleAddNote={(notes) => handleAddNotes(notes, PaymentTypeEnum.PARTNER )} handleAddPayment={(transaction) => handleAddPayment({ ...transaction, type: PaymentTypeEnum.PARTNER }, PaymentTypeEnum.PARTNER)} handleDeletePayment={(transactionId) => handleDeletePayment(transactionId, PaymentTypeEnum.PARTNER)} />
    ) : null}
    <Divider />
    <h2 className={title}>Paperwork</h2>
    <Paperwork type="confirmation" booking={booking} />
    <Paperwork type="voucher" booking={booking} />
    {booking.invoice && (
      <Paperwork type="invoice" booking={booking} onViewClick={() => history.push(`/receipts/${booking.invoice}`)} />
    )}
    <Paperwork type="feedback" booking={booking} />
    <div style={{ padding: '3rem'}} />
  </>
  )}

  return (
    <>
      <QueryGuard loading={loading} error={!!error} data={data && data.booking}>
        {data && data.booking && renderBookingDetails()}  
      </QueryGuard>
      <Modal
          className={modal}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className={modalPaper}>
            <h2>Are you sure you want to delete this request?</h2>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
              <Button color="primary" onClick={cancelBooking} variant='contained'>Delete</Button>
              <Button onClick={() => setIsModalOpen(false)} color="secondary">Cancel</Button>
            </div>
          </div>
        </Modal>
    </>
  );
};

export default withRouter(BookingDetails);
import { Avatar, Card, CardContent, CardHeader, Chip, Collapse, Switch, TextField, makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { ActiveRequest } from '../types';
import moment from 'moment';
import ContextMenu from '../ContextMenu/ContextMenu';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_REQUEST, MARK_REQUEST_COMPLETED, SET_REQUEST_STARTED } from '../graphql/mutations';
import useNotifications from '../../../Notifications/useNotifications';
import { ACTIVE_REQUESTS } from '../graphql/queries';
import { useUserDetails } from '../../../UserContext/UserContex';
import { RouteComponentProps, withRouter } from 'react-router';
interface Props extends RouteComponentProps {
  activeRequest: ActiveRequest
}

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000",
  },
  }

}))

const RequestCard: FC<Props> = ({ activeRequest, history }) => {
  const { input } = useStyles()
  const { addError, addSuccess } = useNotifications()
  const { id } = useUserDetails();
  const [deleteRequest] = useMutation(
    DELETE_REQUEST, 
    { variables: { 
      id: activeRequest.id 
    }, 
    onError: () => addError("There was an error deleting this request."), 
    onCompleted: () => addSuccess('Request deleted'),
    refetchQueries: [{ query: ACTIVE_REQUESTS }],
    awaitRefetchQueries: true
  });

  const [setRequestStarted] = useMutation(SET_REQUEST_STARTED, {     
    onError: () => addError("There was an error completing this request."), 
    onCompleted: () => addSuccess('Request started by you'),
    refetchQueries: [{ query: ACTIVE_REQUESTS }],
    awaitRefetchQueries: true
  });

  const markRequestStarted = (started: boolean) => {
    setRequestStarted({ variables: { id: activeRequest.id, adminId: id, started }})
  }

  const [markRequestCompleted] = useMutation(
    MARK_REQUEST_COMPLETED, 
    { variables: { 
      id: activeRequest.id,
      adminId: id
    }, 
    onError: () => addError("There was an error completing this request."), 
    onCompleted: () => addSuccess('Request completed'),
    refetchQueries: [{ query: ACTIVE_REQUESTS }],
    awaitRefetchQueries: true
  });

  const handleDelete = () => {
    deleteRequest();
  }

  const handleMarkAsCompleted = () => {
    markRequestCompleted();
  }

  const [expanded, setExpanded] = useState<boolean>(false);
  const title = [activeRequest.name, activeRequest.phone,activeRequest.email].filter(Boolean).join(" - ")

  const renderChip = (activeRequestItem: ActiveRequest) => {
    if (!activeRequestItem.started) {
      return null;
    }

    const startedBy = activeRequestItem.started && id === activeRequestItem.startedBy.id ? 'Me' : `${activeRequestItem.startedBy.firstName} ${activeRequestItem.startedBy.lastName}`;

    return <Chip label={`Started by ${startedBy}`} color="primary" variant="outlined" />
  }

  const actions = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {renderChip(activeRequest)}
      <ContextMenu items={[
        ...(!activeRequest.started ? [{ label: 'Working on it', onClick: () => markRequestStarted(true) }] : []),
        { label: 'Mark as completed', onClick: handleMarkAsCompleted },
        { label: 'Edit', onClick: () => history.push(`/requests/edit/${activeRequest.id}`)},
        { label: 'Delete', onClick: handleDelete }
      ]} />
      </div>
  )

  return (
    <Card style={{ marginBottom: "16px" }} onClick={() => setExpanded(!expanded)}>
      <CardHeader 
        avatar={
          <Avatar aria-label="recipe">
            {activeRequest.name[0] && activeRequest.name[0].toUpperCase()  || 'N'}
          </Avatar>
        } 
        title={title} 
        subheader={`Created at ${moment(activeRequest.createdAt).format("D MMM, YYYY, HH:mm")} ${activeRequest.createdBy.firstName ? `by ${activeRequest.createdBy.firstName} ${activeRequest.createdBy.lastName}` : '' }`} 
        action={actions}
        />
      <Collapse in={expanded}>
        <CardContent>
        <TextField
        className={input}
          id="outlined-name"
          label="Notes"
          style={{ width: '100%', marginTop: '0', color: "#9e9e9e!important " }}
          name="notes"
          value={activeRequest.notes}
          onClick={(e) => e.stopPropagation()}
          multiline={true}
          variant="outlined"
          margin="normal"
          disabled={true}
        /> 
        </CardContent>
      </Collapse>
    </Card>
  )
};

export default withRouter(RequestCard);
import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { FC, useState } from 'react';
import { ADD_CUSTOMER_REVIEW } from '../../graphql/mutations';
import { GET_CUSTOMER } from '../../graphql/queries';
import useNotifications from '../../../../Notifications/useNotifications';

interface Props {
  id: string;
  reviewed: boolean
}

const CustomerReview: FC<Props> = ({ id, reviewed }) => {
  const { addSuccess, addError } = useNotifications();
  const [addCustomerReview] = useMutation(
    ADD_CUSTOMER_REVIEW, { 
      refetchQueries: [{ query: GET_CUSTOMER, variables: { id }}], 
      onCompleted: ({ addCustomerReview }) => {
      if (!!addCustomerReview) {
        addSuccess('Successfully updated review state');
      } else {
        addError('There was an error setting review state')
      } 
    }
  })

  const handleAddCustomerReview = () => {
    addCustomerReview({ variables: { id, review: !reviewed }});
  }

  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox onChange={handleAddCustomerReview} checked={reviewed} />} label="Review" />
    </FormGroup>
  )
};

export default CustomerReview;
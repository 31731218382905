import React from 'react';
import { TextField, makeStyles, Fab, Card, Avatar, CardHeader } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { RouteComponentProps } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import useHandleAuthError from '../../utils/hooks/useHandleAuthError';
import QueryGuard from '../../Components/QueryGuard';
import { GET_HOTELS } from './graphql/queries';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    background: 'white',
    borderColor: 'white',
    marginBottom: '2rem'
  },
  grid: {
    textAlign: 'right',
  },
  fab: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
  },
  card: {
    marginBottom: theme.spacing(1),
    width: '100%',
    cursor: 'pointer'
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

interface HotelsProps extends RouteComponentProps {

}

const Hotels = ({ history }: HotelsProps) => {
  const onError = useHandleAuthError()
  const { textField, fab, card, avatar } = useStyles();
  const [keyword, setKeyword] = React.useState<string>('');
  const { loading, error, data, refetch } = useQuery(GET_HOTELS, { variables: { query: keyword || undefined }, onError });


  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
    }
  }

  return (
    <>
      <h1>Hotels</h1>
      <Fab 
        color="primary"
        onClick={() => history.push("/hotels/new")}
        aria-label="add"
        className={fab}
      >
        <AddIcon />
      </Fab>
      <TextField
        id="inline-search"
        label="Search"
        placeholder="Search hotels by name, first name...."
        type="search"
        variant="outlined"
        name="search"
        onKeyPress={onKeyPress}
        className={textField}
        margin="normal"
      />
    <QueryGuard loading={loading} error={!!error} data={data && data.hotels || []}>
      {data && data.hotels && data.hotels.map((hotel, index) => (
          <Card className={card} key={`hotels${index}`}>
            <CardHeader
              avatar={
                <Avatar aria-label="name" className={avatar}>
                  {hotel.name ? hotel.name[0]: ''}
                </Avatar>
              }
              title={hotel.name}
              subheader={hotel.phone}
              onClick={() => history.push(`/hotels/details/${hotel.id}`)}
            />
          </Card>
        )
      )}
    </QueryGuard>
    </>
  )
}

export default Hotels;
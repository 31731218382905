import React from 'react';
import { Formik, FormikProps, Form } from 'formik';
import { Grid, TextField, Button, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { AddUserType } from './types';
import { RouteComponentProps, withRouter } from 'react-router';
import { useUserDetails } from '../../../../UserContext/UserContex';
import { useMutation } from '@apollo/react-hooks';
import useNotifications from '../../../../Notifications/useNotifications';
import { CREATE_USER } from '../graphql/mutations';
import { GET_ADMINS } from '../graphql/queries';

interface AddUserProps extends RouteComponentProps {
}

const customerSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
  email: Yup.string().email().required("Valid email is required."),
  password: Yup.string()
  .required('No password provided.') 
  .min(8, 'Password is too short - should be 8 characters minimum.')
});

const defaultValues: AddUserType = {
  firstName: '',
  email: '',
  lastName: '',
  password: '',
}

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: 'column',
    maxWidth: "75%"
  }
}));

const AddUser = ({ history }: AddUserProps) => {
  const { form, button } = useStyles();
  const { id } = useUserDetails();
  const { addError, addSuccess } = useNotifications()
  const [createUser] = useMutation(CREATE_USER, { 
    onCompleted: () => { 
      addSuccess("Admin user created."); 
      history.push('/operations/admins')
    },
    onError: () => addError('There was an error creating user.'),
    refetchQueries:[{ query: GET_ADMINS }]
  })
    
  const handleSubmit = (values: AddUserType) => {
    createUser({ variables: { adminId: id, ...values }})
  } 

  return (
    <>
    <h1>Add user</h1>
    <Formik
      initialValues={defaultValues}
      validationSchema={customerSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleChange, handleBlur, values }: FormikProps<AddUserType>) => (
        <Form className={form}>

              <TextField
                id="outlined-name"
                label="First name"
                name="firstName"
                value={values.firstName}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.firstName && !!touched.firstName)}
                helperText={(!!errors.firstName && !!touched.firstName) && errors.firstName}
                margin="normal"
              />

              <TextField
                id="outlined-name"
                label="Last name"
                name="lastName"
                value={values.lastName}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.lastName && !!touched.lastName)}
                helperText={(!!errors.lastName && !!touched.lastName) && errors.lastName}
                margin="normal"
              />

              <TextField
                id="outlined-name"
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.email && !!touched.email)}
                helperText={(!!errors.email && !!touched.email) && errors.email}
                margin="normal"
              />
              <TextField
                id="outlined-name"
                label="Password"
                name="password"
                type='password'
                value={values.password}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.password && !!touched.password)}
                helperText={(!!errors.password && !!touched.password) && errors.password}
                margin="normal"
              />
              <div>
                <Button variant="contained" className={button} type="submit" color="primary">
                  Create user
                </Button>
              </div>
        </Form>
      )}
    </Formik>
    </>
  )
}

export default withRouter(AddUser);
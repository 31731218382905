import { gql } from "apollo-boost";

export const ANALYTICS = gql`
  query analytics {
    getAnalytics {
      booking {
        customerRemaining
        customerTransactions
        partnerRemaining
        partnerTransactions
      }
      ticket {
        customerRemaining
        customerTransactions
        partnerRemaining
        partnerTransactions
      }
      
      giftCard {
        customerRemaining
        customerTransactions
        partnerRemaining
        partnerTransactions
      }
    }
  }
`
import { IconButton, MenuItem, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { FC } from 'react';
import { Option } from './types';

interface Props {
  options: Option[];
  onChange: (event) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  value: any;
  label: string;
  name?: string;
  style?: any;
}

const Select: FC<Props> = ({ onBlur, onChange, value, options, name, style, label }) => {
  return (
    <TextField
      variant="outlined"
      style={style}
      select
      name={name}
      SelectProps={{
        onChange: onChange,
        onBlur: onBlur,
        value
      }}
      InputProps={{
        startAdornment: (
          <IconButton style={{ display: 'relative', right: '1rem', order: 1}} onClick={() => onChange({ target: { value: null }})} disabled={!value}>
            <ClearIcon color="disabled" fontSize="small" />
          </IconButton>
        )
        }}

      label={label}
    >
      {options.map((option, key) => (
        <MenuItem key={key} id={`${key}`} value={option.value}>{option.name}</MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
import moment from "moment";

export const getInitials = (first: string, second: string) => {
  const firstLetter = first ? first[0] : '';
  const secondLetter = second ? second[0] : ''

  return `${firstLetter}${secondLetter}`;
};

export const dateFormat = 'YYYY-MM-DD';
export const formatMonthDayYear = (value: number | string): string => moment(value).format('MMM DD, YYYY');

export const formatMoney = (value: number) => {
  const amount = !value ? 0 : value;

  return `€ ${amount.toFixed(2)}`
};
import React from 'react';
import { Card, CardHeader, Avatar, IconButton, Typography, makeStyles, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import MailIcon from '@material-ui/icons/Mail';
import Phone from '@material-ui/icons/Phone';
import EditIcon from '@material-ui/icons/Edit';
import PinIcon from '@material-ui/icons/PinDrop';
import { red } from '@material-ui/core/colors';
import { withRouter, RouteComponentProps } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { HotelDetails } from '../types';
import useNotifications from '../../../Notifications/useNotifications';
import { DELETE_HOTEL } from '../graphql/mutations';
import { GET_HOTELS } from '../graphql/queries';

interface HotelInfoProps extends RouteComponentProps {
  hotel: HotelDetails;
}

const useStyles = makeStyles(() => ({
  card: {
    marginBottom: '1rem',
  },
  header: {
    paddingBottom: '1rem',
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  lineSpace: {
    marginBottom: '0.3rem'
  },
  title: {
    marginBottom: '0.5rem',
  },
  icon: {
    marginRight: '0.5rem',
    width: '1rem',
    height: '1rem',
  },
  avatar: {
    backgroundColor: red[500],
    height: '3rem',
    width: '3rem',
  },
}));

const HotelInfo = ({ hotel, history }: HotelInfoProps) => {
  const { card, avatar, verticalCenter, lineSpace, icon, title } = useStyles();
  const hasBookings = !!hotel.bookings.length
  const { addSuccess, addError } = useNotifications();
  const [deleteHotel] = useMutation(DELETE_HOTEL, { 
    variables: { id: hotel.id },
    onCompleted: () => {

      addSuccess('Hotel deleted successfully');
      history.push("/hotels")
      
    },
    onError: () => addError('There was an error deleting this hotel'),
    refetchQueries:[{ query: GET_HOTELS }],
    awaitRefetchQueries: true
  })

  const renderDeleteButton = () => {
    if(hasBookings) {
     return (
        <IconButton disabled={hasBookings} aria-label="settings">
          <DeleteIcon />
        </IconButton>
      )
    }

    return (
      <IconButton onClick={() => deleteHotel()} aria-label="settings">
        <DeleteIcon />
      </IconButton>
    )
  }

  return (
    <>
      <h1>Details</h1>
      <Card className={card}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={avatar}>
              {hotel.name[0]}
            </Avatar>
          }
          action={
            <>
              <IconButton onClick={() => history.push(`/hotels/edit/${hotel.id}`)} aria-label="settings">
                <EditIcon />
              </IconButton>
              {renderDeleteButton()}
              
            </>
          }
          title={
            <Typography 
              className={title}
              variant={"h6"}
              component={"h6"}
              color="textPrimary">
               {hotel.name}
            </Typography> 
          }
          subheader={
            <>
              <Typography style={{ marginBottom: "0.5rem"}} className={verticalCenter} variant="body2" color="textSecondary" component="p">
                <Phone className={icon}/> {hotel.phone}
              </Typography>
              <Typography className={`${verticalCenter} ${lineSpace}`} variant="body2" color="textSecondary" component="p">
                <PinIcon className={icon}/> {hotel.address}
              </Typography>
            </>
          }
        />
      </Card>
    </>
  )
}

export default withRouter(HotelInfo);
import React, { FC } from 'react';
import { RequestFormType } from '../RequestForm/types';
import { RouteComponentProps } from 'react-router';
import RequestForm from '../RequestForm/RequestForm';
import { CREATE_REQUEST } from '../graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import useNotifications from '../../../Notifications/useNotifications';
import { useUserDetails } from '../../../UserContext/UserContex';
import { ACTIVE_REQUESTS } from '../graphql/queries';

interface Props extends RouteComponentProps {

}

const NewRequest: FC<Props> = ({ history }) => {
  const { addError, addSuccess } = useNotifications();
  const { id } = useUserDetails();
  const [createRequest] = useMutation(CREATE_REQUEST, {
    onError: () => addError('Something went wrong, please try again.'),
    onCompleted: () => { 
      console.log("request")
      addSuccess('Request created');
      history.push('/requests');
    },
    refetchQueries: [{ query: ACTIVE_REQUESTS }],
    awaitRefetchQueries: true
  });


  const handleSubmit = async (values: RequestFormType): Promise<void> => {
    await createRequest({ variables: { id, input: values }});
  }

  return (
    <><h1>New request</h1><RequestForm onSubmit={handleSubmit} /></>
  )
};

export default NewRequest;
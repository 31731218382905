import { useMutation } from "@apollo/react-hooks";
import { ActionItemTypeEnum, ItemType, PaymentTypeEnum } from "../../Customers/types/types";
import { IGNORE_ACTION_ITEM } from "./graphql/mutation";
import useNotifications from "../../../Notifications/useNotifications";
import { GET_ACTION_ITEMS } from "../../Customers/graphql/queries";
import { GET_BOOKING } from "../../Bookings/graphql/queries";

export const useActionItemMutations = (itemType: ItemType, id: string) => {
  const { addSuccess, addError } = useNotifications();

  const [ignoreActionItem] = useMutation(IGNORE_ACTION_ITEM, {
    onCompleted: ({ ignoreActionItem }) => {
      if (!!ignoreActionItem) {
        addSuccess('Notification marked as ignored');
      } else {
        addError('Could not mark notification as ignored')
      } 
    },
    refetchQueries: [{ query: GET_ACTION_ITEMS }, { query: GET_BOOKING, variables: { id } }],
    awaitRefetchQueries: true,
  });

  const handleRemoveActionItem = (paymentType: PaymentTypeEnum, type: ActionItemTypeEnum) => ignoreActionItem({ variables: { id, paymentType, actionItemType: type }})

  return {
    handleRemoveActionItem
  }

}
import React from 'react';
import { Formik, FormikProps, Form } from 'formik';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import { Grid, TextField, Button, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import moment from 'moment';
import { GiftVoucherInput } from '../type';


interface GiftVoucherFormProps {
  initialValues?: GiftVoucherInput;
  onSubmit: (value: GiftVoucherInput) => void
}

const giftVoucherSchema = Yup.object().shape({
  amount: Yup.number().positive('Must be a positive number').required('Required'),
});

const defaultValues: { amount: number; code?: string } = {
  amount: 0,
}

const useStyles = makeStyles(theme => ({
  input: {
    width: '80%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
  }
}));

const GiftVoucherForm = ({ onSubmit, initialValues = defaultValues }: GiftVoucherFormProps) => {
  const { form, input, button } = useStyles();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={giftVoucherSchema}
    >
      {({ errors, touched, handleChange, handleBlur, values, setFieldValue }: FormikProps<GiftVoucherInput>) => (
        <Form className={form}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-name"
                label="Amount"
                name="amount"
                value={values.amount}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.amount && !!touched.amount)}
                helperText={(!!errors.amount && !!touched.amount) && errors.amount}
                margin="normal"
                className={input}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-name"
                label="Code"
                name="code"
                value={values.code}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                margin="normal"
                helperText="Code will be automatically generated. Only provide if you already issued a voucher with a custom code."
                className={input}
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" className={button} type="submit" color="primary">
                Create ticket
              </Button>
            </Grid>
          </Grid>
          </MuiPickersUtilsProvider>

        </Form>
      )}
    </Formik>
  )
}

export default GiftVoucherForm;
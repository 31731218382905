import './index.css';
import { ApolloProvider } from '@apollo/react-hooks';


import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import history from "./history";
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
import AuthProvider from './AuthContext/AuthProvider';
import UserProvider from './UserContext/UserProvider';

const httpLink = createHttpLink({
  uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');
  console.log('Token', token);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});


const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
    ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <UserProvider>
    <AuthProvider>
      <ApolloProvider client={client}><App /></ApolloProvider>
    </AuthProvider>
  </UserProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();

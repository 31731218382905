import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Sidebar from '../Sidebar';
import AppHeader from '../AppHeader';
import MainContent from '../MainContent';
import NotificationProvider from '../Notifications/NotificationProvider';
import UserProvider from '../UserContext/UserProvider';

export const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

const AppContainer = () => {
  const classes = useStyles();
  const [isOpened, setIsOpened] = React.useState(true);

  const handleDrawerOpen = () => setIsOpened(true);

  const handleDrawerClose = () => setIsOpened(false);

  return (
    <div className={classes.root}>
      <NotificationProvider>
      <CssBaseline />
      <AppHeader onSidebarOpen={handleDrawerOpen} sidebarOpen={isOpened} />
      <Sidebar 
        onClose={handleDrawerClose} 
        isOpened={isOpened}
      />
      <MainContent />
      </NotificationProvider>
    </div>
  );
};

export default AppContainer;

import React, { FC } from 'react';
import { Receipt } from '../types';

import { Formik, FormikProps, Form } from 'formik';
import { TextField, Button, makeStyles, Switch, FormControlLabel } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  spacing: {
    marginRight: '1rem',
  },
  button: {
    marginTop: '2rem',
    marginBottom: '5rem'
  },
  input: {
    width: '75%',
    minWidth: '12rem',
  }
}));

interface Props extends RouteComponentProps {
  initialValues?: Receipt;
  title: string;
  onSubmit: (values: Receipt) => void;
}

const baseInitialValues: Receipt = {
  bookingId: '',
  customerId: '',
  name: '',
  fullName: '',
  profession: '',
  address: '',
  city: '',
  telephone: '',
  afm: '',
  doy: '',
  documentType: '',
  series: '',
  number: '',
  paymentType: '',
  date: moment().format('YYYY-MM-DD'),
  total: '',
  hotelName: '',
  numberOfPeople: '',
  checkIn: '',
  checkOut: '',
  isVat: false
};

const ReceiptForm: FC<Props> = ({ title, initialValues, onSubmit }) => {
  const { input, button, header } = useStyles();

  return (
    <>
      <Formik
        initialValues={{ ...baseInitialValues, ...initialValues }}
        onSubmit={onSubmit}
      >
        {({ errors, touched, handleChange, handleBlur, values, setFieldValue }: FormikProps<Receipt>) => (
          <Form>
            <div className={header}>
              <div style={{ padding: '2rem 0', fontWeight: 700, fontSize: '1.5rem' }}>{title}</div>
              {console.log(values.isVat)}
                <FormControlLabel
                  control={<Switch name="isVat" checked={values.isVat} onChange={handleChange} />}
                  label="VAT"
                  labelPlacement="start"
                />
              </div>
            {values.isVat && (
              <div>
                <TextField
                  id="outlined-name"
                  className={input}
                  label="Name"
                  variant="outlined"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(!!errors.name && !!touched.name)}
                  helperText={(!!errors.name && !!touched.name) && errors.name}
                  margin="normal"
                />
              </div>
            )}
            <div>
              <TextField
                id="outlined-name"
                label="Full name"
                className={input}
                variant="outlined"
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.fullName && !!touched.fullName)}
                helperText={(!!errors.fullName && !!touched.fullName) && errors.fullName}
                margin="normal"
              />
            </div>
            {values.isVat && (
              <>
                <div>
                  <TextField
                    id="outlined-name"
                    label="Proffesion"
                    className={input}
                    variant="outlined"
                    name="profession"
                    value={values.profession}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={(!!errors.profession && !!touched.profession)}
                    helperText={(!!errors.profession && !!touched.profession) && errors.profession}
                    margin="normal"
                  />
                </div>

                <div>
                  <TextField
                    id="outlined-name"
                    label="Address"
                    className={input}
                    variant="outlined"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={(!!errors.address && !!touched.address)}
                    helperText={(!!errors.address && !!touched.address) && errors.address}
                    margin="normal"
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-name"
                    label="City"
                    className={input}
                    name="city"
                    variant="outlined"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={(!!errors.city && !!touched.city)}
                    helperText={(!!errors.city && !!touched.city) && errors.city}
                    margin="normal"
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-name"
                    label="Telephone number"
                    className={input}
                    name="telephone"
                    value={values.telephone}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={(!!errors.telephone && !!touched.telephone)}
                    helperText={(!!errors.telephone && !!touched.telephone) && errors.telephone}
                    margin="normal"
                  />
                </div>

                <div>
                  <TextField
                    id="outlined-name"
                    label="AFM"
                    className={input}
                    name="afm"
                    value={values.afm}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={(!!errors.afm && !!touched.afm)}
                    helperText={(!!errors.afm && !!touched.afm) && errors.afm}
                    margin="normal"
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-name"
                    label="DOY"
                    className={input}
                    name="doy"
                    value={values.doy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    error={(!!errors.doy && !!touched.doy)}
                    helperText={(!!errors.doy && !!touched.doy) && errors.doy}
                    margin="normal"
                  />
                </div>

                <div>
                  <TextField
                    id="outlined-name"
                    label="Series"
                    className={input}
                    name="series"
                    value={values.series}
                    onChange={handleChange}
                    variant="outlined"
                    onBlur={handleBlur}
                    error={(!!errors.series && !!touched.series)}
                    helperText={(!!errors.series && !!touched.series) && errors.series}
                    margin="normal"
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-name"
                    label="Number"
                    className={input}
                    name="number"
                    value={values.number}
                    onChange={handleChange}
                    variant="outlined"
                    onBlur={handleBlur}
                    error={(!!errors.number && !!touched.number)}
                    helperText={(!!errors.number && !!touched.number) && errors.number}
                    margin="normal"
                  />
                </div>
              </>
            )}
            <div>
              <TextField
                id="outlined-name"
                label="Payment type"
                className={input}
                name="paymentType"
                value={values.paymentType}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.paymentType && !!touched.paymentType)}
                helperText={(!!errors.paymentType && !!touched.paymentType) && errors.paymentType}
                margin="normal"
              />
            </div>
            <div>
              <TextField
                id="outlined-name"
                label="Total"
                className={input}
                name="total"
                value={values.total}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.total && !!touched.total)}
                helperText={(!!errors.total && !!touched.total) && errors.total}
                margin="normal"
              />
            </div>

            <div>
              <TextField
                id="outlined-name"
                label="Hotel name"
                className={input}
                name="hotelName"
                value={values.hotelName}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.hotelName && !!touched.hotelName)}
                helperText={(!!errors.hotelName && !!touched.hotelName) && errors.hotelName}
                margin="normal"
              />
            </div>
            <div>
              <TextField
                id="outlined-name"
                label="Number of people"
                className={input}
                name="numberOfPeople"
                value={values.numberOfPeople}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.numberOfPeople && !!touched.numberOfPeople)}
                helperText={(!!errors.numberOfPeople && !!touched.numberOfPeople) && errors.numberOfPeople}
                margin="normal"
              />
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    onFocus={() => {}}
                    format="dd/MM/yyyy"
                    margin="normal"
                    className={input}
                    id="date-picker-inline"
                    label="Check in"
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    name="checkIn"
                    value={values.checkIn}
                    onChange={(date, value) => setFieldValue('checkIn', date)}
                    onBlur={handleBlur}
                    error={(!!errors.checkIn && !!touched.checkIn)}
                    helperText={(!!errors.checkIn && !!touched.checkIn) && errors.checkIn}
                  />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    onFocus={() => {}}
                    margin="normal"
                    className={input}
                    id="date-picker-inline"
                    label="Check out"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    name="checkOut"
                    value={values.checkOut}
                    onChange={(date, value) => setFieldValue('checkOut', date)}
                    onBlur={handleBlur}
                    inputVariant="outlined"
                    error={(!!errors.checkOut && !!touched.checkOut)}
                    helperText={(!!errors.checkOut && !!touched.checkOut) && errors.checkOut}
                  />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    onFocus={() => {}}
                    format="dd/MM/yyyy"
                    margin="normal"
                    className={input}
                    id="date-picker-inline"
                    label="Date"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    name="date"
                    value={values.date}
                    inputVariant="outlined"
                    onChange={(date, value) => setFieldValue('date', date)}
                    onBlur={handleBlur}
                    error={(!!errors.date && !!touched.date)}
                    helperText={(!!errors.date && !!touched.date) && errors.date}
                  />
              </MuiPickersUtilsProvider>
            </div>
              <Button className={button} variant="contained" type="submit" color="primary">
                Create invoice
              </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withRouter(ReceiptForm);
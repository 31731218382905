import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { MatchParams } from '../../Customers/types/types';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BookingForm from '../BookingForm';
import { CREATE_BOOKING } from '../../Customers/graphql/mutations';
import { dateFormat } from '../../../utils/utils';
import { GET_CUSTOMER, GET_ACTION_ITEMS } from '../../Customers/graphql/queries';
import useNotification from '../../../Notifications/useNotifications';
import { BookingInput } from '../types';
import { BookingValues } from '../BookingForm/BookingForm';
import moment from 'moment';
import { useUserDetails } from '../../../UserContext/UserContex';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(8),
  },
}));

const NewBooking = ({ match, history }: RouteComponentProps<MatchParams>) => {
  const id = match.params.id;
  const { firstName, lastName } = useUserDetails();
  const { addSuccess, addError } = useNotification();

  const [createBooking] = useMutation(CREATE_BOOKING, 
    { 
      refetchQueries: [{ query: GET_CUSTOMER, variables: { id } }, { query: GET_ACTION_ITEMS }],
      awaitRefetchQueries: true,
      onCompleted: ({ createBooking }) => {
        if(!!createBooking) {
          addSuccess('New booking created successfully');
          history.push(`/customers/details/${id}`)
        } else {
          addError('Error creating booking. Please try again.')
        }
      },
    },
  );

  const onSubmit = async (booking: BookingValues) => {
    const checkIn = moment(booking.checkIn).toISOString();
    const checkOut = moment(booking.checkOut).toISOString();
    const reservationDate = moment().format(dateFormat);
    console.log(booking)
    const paperwork = {
      confirmation: false,
      invoice: false,
      voucher: false,
    }

    const values: BookingInput = {
      checkIn,
      checkOut, 
      reservationDate,
      total: booking.total,
      partnerTotal: booking.partnerTotal ? + booking.partnerTotal : 0,
      paperwork,
      partner: booking.partner,
      packageName: booking.packageName,
      customerId: id,
      adults: booking.adults,
      children: booking.children,
      childrenAge: booking.childrenAge,
      infants: booking.infants,
      roomType: booking.roomType,
      hotel: booking.hotel,
      note: booking.note,
      board: booking.board,
      extra: booking.extra,
      view: booking.view,
      createdBy: `${firstName} ${lastName}`
    }

    console.log(values, id)
    await createBooking({ variables: { id, values }})
  };

  const { header } = useStyles();
  
  return (
    <>
      <>
        <h1 className={header}>New Booking</h1>
        <BookingForm onSubmit={onSubmit} />
      </>
    </>
  )
};

export default withRouter(NewBooking);
import React from 'react';
import { Card, CardHeader, Avatar, IconButton, Typography, makeStyles } from '@material-ui/core';
import { Customer } from '../../types/types';
import { getInitials } from '../../../../utils/utils';
import DeleteIcon from '@material-ui/icons/Delete';

import MailIcon from '@material-ui/icons/Mail';
import Phone from '@material-ui/icons/Phone';
import EditIcon from '@material-ui/icons/Edit';
import { red } from '@material-ui/core/colors';
import { withRouter, RouteComponentProps } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_CUSTOMER } from '../../graphql/mutations';
import useNotifications from '../../../../Notifications/useNotifications';
import { GET_CUSTOMERS } from '../../graphql/queries';

interface CustomerInfoProps extends RouteComponentProps {
  customer: Customer;
  hasBookings: boolean;
}

const useStyles = makeStyles(() => ({
  card: {
    marginBottom: '1rem',
  },
  header: {
    paddingBottom: '1rem',
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  lineSpace: {
    marginBottom: '0.3rem'
  },
  title: {
    marginBottom: '0.5rem',
  },
  icon: {
    marginRight: '0.5rem',
    width: '1rem',
    height: '1rem',
  },
  avatar: {
    backgroundColor: red[500],
    height: '3rem',
    width: '3rem',
  },
}));

const CustomerInfo = ({ customer, history, hasBookings }: CustomerInfoProps) => {
  const { header, card, avatar, verticalCenter, lineSpace, icon, title } = useStyles();
  const { addSuccess, addError } = useNotifications();
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, { 
    variables: { id: customer.id },
    onCompleted: ({ deleteCustomer }) => {
      console.log(deleteCustomer)
      if (!!deleteCustomer) {
        addSuccess('Customer deleted successfully');
        history.push("/customers")
      } else {
        addError('There was an error deleting this customer')
      } 
    }
  })

  return (
    <>
      <h1>Details</h1>
      <Card className={card}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={avatar}>
              {getInitials(customer.firstName, customer.lastName)}
            </Avatar>
          }
          action={
            <>
              <IconButton onClick={() => history.push(`/customers/edit/${customer.id}`)} aria-label="settings">
                <EditIcon />
              </IconButton>
              <IconButton disabled={hasBookings} onClick={() => deleteCustomer()} aria-label="settings">
                <DeleteIcon />
              </IconButton>
              
            </>
          }
          title={
            <Typography 
              className={title}
              variant={"h6"}
              component={"h6"}
              color="textPrimary">
               {customer.firstName} {customer.lastName}
            </Typography> 
          }
          subheader={
            <>
              <Typography className={`${verticalCenter} ${lineSpace}`} variant="body2" color="textSecondary" component="p">
                <MailIcon className={icon}/> {customer.email}
              </Typography>
              <Typography className={`${verticalCenter} ${lineSpace}`} variant="body2" color="textSecondary" component="p">
                <Phone className={icon}/> {customer.phone}
              </Typography>
             {customer.afm && <Typography className={`${verticalCenter} ${lineSpace}`} variant="body2" color="textSecondary" component="p">
                AFM: {customer.afm}
              </Typography>}
              {customer.notes && <Typography className={`${verticalCenter} ${lineSpace}`} variant="body2" color="textSecondary" component="p">
                Notes: {customer.notes}
              </Typography>}
            </>
          }
        />
      </Card>
    </>
  )
}

export default withRouter(CustomerInfo);
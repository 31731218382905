import React from 'react';
import { Formik, FormikProps, Form } from 'formik';
import { Grid, TextField, Button, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { Customer } from '../types/types';

interface CustomerFormProps {
  initialValues?: Customer;
  onSubmit: (value: Customer) => void
}

const customerSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  phone: Yup.string()
    .required('Required'),
});

const defaultValues: Customer = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
}

const useStyles = makeStyles(theme => ({
  input: {
    width: '80%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
  }
}));

const CustomerForm = ({ onSubmit, initialValues = defaultValues }: CustomerFormProps) => {
  const { form, input, button } = useStyles();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={customerSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, handleChange, handleBlur, values }: FormikProps<Customer>) => (
        <Form className={form}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="First Name"
                name="firstName"
                value={values.firstName}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.firstName && !!touched.firstName)}
                helperText={(!!errors.firstName && !!touched.firstName) && errors.firstName}
                margin="normal"
                className={input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Last Name"
                name="lastName"
                value={values.lastName}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.lastName && !!touched.lastName)}
                helperText={(!!errors.lastName && !!touched.lastName) && errors.lastName}
                margin="normal"
                className={input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.email && !!touched.email)}
                helperText={(!!errors.email && !!touched.email) && errors.email}
                margin="normal"
                className={input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Phone"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.phone && !!touched.phone)}
                helperText={(!!errors.phone && !!touched.phone) && errors.phone}
                margin="normal"
                className={input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="AFM"
                name="afm"
                value={values.afm}
                multiline={true}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                margin="normal"
                className={input}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                label="Notes"
                name="notes"
                value={values.notes}
                multiline={true}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={(!!errors.notes && !!touched.notes)}
                helperText={(!!errors.notes && !!touched.notes) && errors.notes}
                margin="normal"
                className={input}
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" className={button} type="submit" color="primary">
                Create customer
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default CustomerForm;
import React, { FC, useState } from 'react';
import UserContext from './UserContex';

const UserProvider: FC = ({ children }) => {
  const [firstName, setFirstName] = useState<string>(() => {
    const firstName = localStorage.getItem('app-firstName');

    if (!firstName || firstName === '') {
      return '';
    } else {
      return firstName;
    }
  });
  const [lastName, setLastName] = useState<string>(() => {
    const lastName = localStorage.getItem('app-lastName');

    if (!lastName|| lastName === '') {
      return '';
    } else {
      return lastName;
    }
  });

  const [id, setId] = useState<string>(() => {
    const appId = localStorage.getItem('app-id');

    if (!appId|| appId === '') {
      return '';
    } else {
      return appId;
    }
  });

  const setUser = (userFirstName: string, userLastName: string, id: string) => {
    localStorage.setItem('app-firstName', userFirstName);
    localStorage.setItem('app-lastName', userLastName);
    localStorage.setItem('app-id', id)

    setFirstName(userFirstName);
    setLastName(userLastName);
    setId(id)
  }

  const unsetUser = () => {
    localStorage.removeItem('app-firstName');
    localStorage.removeItem('app-lastName');
    localStorage.removeItem('app-id')
  }

  return (
    <UserContext.Provider value={{ firstName, lastName, id, setUser, unsetUser }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
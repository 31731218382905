import gql from "graphql-tag";

export const GET_ADMINS = gql`
  query admins {
    admins {
      id
      firstName
      lastName
      email
    }
  }
`;
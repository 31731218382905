import gql from "graphql-tag";

export const GET_INVOICES = gql`
   query Invoices($dateFrom: String, $dateUntil: String, $isVat: Boolean) {
    invoices(dateFrom: $dateFrom, dateUntil: $dateUntil, isVat: $isVat) {
      id
      name
      fullName
      date
      total
      hotelName
    }
  }
`;

export const GET_INVOICE = gql`
  query Invoice($id: String) {
    invoice(id: $id) {
      bookingId
      customerId
      name
      fullName
      profession
      address
      city
      telephone
      afm
      doy
      documentType
      series
      number
      paymentType
      date
      total
      hotelName
      numberOfPeople
      checkIn
      checkOut
      isVat
    }
  }
`


import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { MatchParams } from '../../Customers/types/types';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import TicketForm from '../TicketForm';
import { GET_CUSTOMER, GET_ACTION_ITEMS, GET_CUSTOMER_TICKETS } from '../../Customers/graphql/queries';
import useNotification from '../../../Notifications/useNotifications';

import { TicketFormType } from '../TicketForm/TicketForm';
import moment from 'moment';
import { CREATE_TICKET } from '../graphql/mutations';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(8),
  },
}));

const NewTicket = ({ match, history }: RouteComponentProps<MatchParams>) => {
  const id = match.params.id;
  const { addSuccess, addError } = useNotification();
  const { header } = useStyles();

  const [createTicket] = useMutation(CREATE_TICKET, 
    { 
      refetchQueries: [{ query: GET_CUSTOMER, variables: { id } }, { query: GET_ACTION_ITEMS }, { query: GET_CUSTOMER_TICKETS, variables: { customerId: id }}],
      awaitRefetchQueries: true,
      onCompleted: ({ createTicket }) => {
        if(!!createTicket) {
          addSuccess('New ticket created successfully');
          history.push(`/customers/details/${id}`)
        } else {
          addError('Error creating booking. Please try again.')
        }
      },
    },
  ); 

  const onSubmit = async (values: TicketFormType) => {
    const departureDate = moment(values.date).toISOString();

    console.log(values, id)
    await createTicket({ variables: { values: { ...values, date: departureDate, customerId: id } }})
  };
  
  return (
    <>
      <>
        <h1 className={header}>New Ticket</h1>
        <TicketForm onSubmit={onSubmit} />
      </>
    </>
  )
};

export default withRouter(NewTicket);
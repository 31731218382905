import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { GET_CUSTOMER_TICKETS } from '../../graphql/queries';
import { formatMoney, formatMonthDayYear } from '../../../../utils/utils';
import QueryGuard from '../../../../Components/QueryGuard';
import Table from '../../../../Components/Table';
import { Ticket } from '../../../Tickets/types';

interface Props extends RouteComponentProps {
  customerId: string;
}

const CustomerTickets: FC<Props> = ({ history, customerId }) => {
  const { data, error, loading } = useQuery(GET_CUSTOMER_TICKETS, { variables: { customerId }});

  const renderTicketTable = (tickets: Ticket[]) => {
    const tableData = tickets.map((ticket) => ({
      data: {
        company: ticket.company? ticket.company : "-",
        date: formatMonthDayYear(+ticket.date),
        total: ticket.customerPayment ? formatMoney(ticket.customerPayment.total.amount || 0) : "-",
        partner: ticket.partner || '-'
      },
      onRowClick: () => history.push(`/tickets/${ticket.id}`)
    }))

    return (<Table tableData={tableData} />);
  }

  return (
    <QueryGuard data={data && data.tickets} loading={loading} error={error ? !!error : false} displayNoResult={false}>
        {data && data.tickets && 
          <div>
            <h2>Tickets</h2>
            <div>
              {renderTicketTable(data.tickets)}
            </div>
        </div>
      }
    </QueryGuard>
  );
};

export default withRouter(CustomerTickets);

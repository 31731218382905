import React from 'react';
import { startCase } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton, Card, CardHeader } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import ActionItemAvatar from '../../../Components/ActionItemAvatar/ActionItemAvatar';
import { ActionItemTypeEnum, IActionItem, ItemType, PaymentTypeEnum } from '../../Customers/types/types';
import moment from 'moment';
import { formatMoney } from '../../../utils/utils';
import { useActionItemMutations } from './useActionItemMutations';

const titleConfig = { 
  [PaymentTypeEnum.CUSTOMER]: 'Customer',
  [PaymentTypeEnum.PARTNER]: 'Agency'
}

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  card: {
    marginBottom: theme.spacing(1),
    width: '100%',
    border: '1px solid lightgrey'
  },
  paper: {
    position: 'absolute',
    top: 36,
  },
  options: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    display: 'flex',
    marginBottom: theme.spacing(1),

  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    "&:hover": {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  boldFont: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  descriptionFont: {
    fontSize: '1rem',
    fontWeight: 'normal',
  }
}));

interface ActionItemProps extends RouteComponentProps {
  item: IActionItem;
  details?: boolean;
  completed?: boolean;
}

const ActionItem = ({ item, details = true, completed, history }: ActionItemProps) => {
  const { root, card, boldFont, descriptionFont, title } = useStyles();
  const { date, amount, description, name, type, id, remaining, paymentType, itemType } = item;
  console.log({ description, name, type, paymentType })
  const { handleRemoveActionItem } = useActionItemMutations(itemType, id)

  const renderActions = () => {
    return completed || type === ActionItemTypeEnum.MISSING_PROFORMA || itemType !== ItemType.BOOKING  ? (null) : (
      <div style={{ display: 'flex' }}>
        <IconButton onClick={() => handleRemoveActionItem(paymentType, type)} className={boldFont} color="primary" aria-label="more">
          <DeleteForeverIcon />
        </IconButton>
      </div>
    )
  }


  const onTitleClick = () => {
    const urlMap = {
      booking: `/bookings/${id}`,
      ticket: `/tickets/${id}`,
      giftCard: `/gift-vouchers/${id}`
    }

    history.push(urlMap[itemType])
  };
  
  return (
    <div className={root}>
      <Card className={card}>
        <CardHeader
          className={boldFont}
          avatar={
            <ActionItemAvatar type={type} paymentType={paymentType} />
          }
          action={renderActions()}
          title={
            <>
              <div onClick={details ? onTitleClick : undefined} className={details ? title : boldFont}>
                {titleConfig[paymentType]} - {startCase(type)} {details && (<>- {name} - {description} - Total: {formatMoney(amount)}</>)} 
              </div>
            </>
            }
          subheader={<div className={descriptionFont}> Remaining amount: {formatMoney(remaining)} - Deadline: {moment(+date).format('DD, MMMM YYYY')}</div>}
        />
      </Card>
    </div>
  );
};

export default withRouter(ActionItem);
import React from 'react';
import { Paper, makeStyles, Divider } from '@material-ui/core';
import PageHeader from '../../../Components/PageHeader';
import { useMutation } from '@apollo/react-hooks';
import { Customer } from '../types/types';
import { CREATE_CUSTOMER } from '../graphql/mutations';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CustomerForm from '../CustomerForm';
import { GET_CUSTOMERS } from '../graphql/queries';
import useNotifications from '../../../Notifications/useNotifications';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

const NewCustomer = ({ history }: RouteComponentProps) => {
  const { addSuccess, addError } = useNotifications(); 
  const { paper, header } = useStyles();

  const [createCustomer] = useMutation(CREATE_CUSTOMER, 
    { 
      refetchQueries: [{ query: GET_CUSTOMERS }],
      awaitRefetchQueries: true,
      onCompleted: ({ createCustomer }) => {
        if(!!createCustomer) {
          history.push(`/customers/details/${createCustomer.id}`)
          addSuccess('Customer created successfully');
        } else {
          addError('Error creating customer. Please try again.')
        }
      }
    }
  );

  const onSubmit = (values: Customer) => createCustomer({ variables: values });
  
  return (
    <>
      <Paper className={paper}>
        <PageHeader title="New Customer" className={header}/>
        <Divider variant="fullWidth" />
        <CustomerForm onSubmit={onSubmit} />
      </Paper>
    </>
  )
};

export default withRouter(NewCustomer);
export interface Customer {
  id?: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  afm?: string;
  notes?: string;
}

export interface MatchParams {
  id: string;
}

export interface Booking {
  id?: string;
  customer: Customer;
  packageName: string;
  reservationDate: string;
  checkIn: string;
  checkOut: string;
  adults: number;
  children: number;
  partnerPayment: Payment;
  customerPayment: Payment;
  infants: number;
  roomType: string;
  childrenAge: string;
  hotel: Hotel;
  note: string;
  board: string;
  extra: string;
  paperwork: Paperwork;
  view: string;
  createdBy: string;
  invoice: string;
}

export interface Hotel {
  id: string;
  name: string;
  address?: string;
  phone?: string;
}

export interface Paperwork {
  confirmation: boolean;
  voucher: boolean;
  invoice: boolean;
}

export type Transaction = {
  id?: string
  date: string
  amount: number
  bank: string
  type: string
  code?: string
}

export interface PaymentType {
  date: string
  amount: number
  paid: boolean
  remaining: number
}

export interface Payment {
  deposit: PaymentType;
  total: PaymentType;
  name: string;
  payments: [Transaction];
  notes?: string;
}

export interface IActionItem extends PaymentType {
  id: string;
  description: string;
  paymentType: PaymentTypeEnum;
  type: ActionItemTypeEnum;
  itemType: ItemType
  customerId: string;
  name: string;
}

export enum ItemType {
  BOOKING = 'booking',
  TICKET = 'ticket',
  GIFT_CARD = 'giftCard'
}

export enum ActionItemTypeEnum {
  DEPOSIT = 'deposit',
  TOTAL = 'total',
  MISSING_PROFORMA = "missingProforma"
}

export enum PaymentTypeEnum {
  CUSTOMER = 'customerPayment',
  PARTNER = 'partnerPayment'
}

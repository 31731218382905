import React, { FC, ReactElement, ReactNode } from 'react';
import { isEmpty } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import noresult from './no-search-result.svg'
import errorpic from './error-pic.svg'

interface Props {
  loading?: boolean;
  error?: boolean;
  data?: any;
  noResult?: ReactElement;
  displayNoResult?: boolean;
}

const QueryGuard: FC<Props> = ({ loading, error, children, data = [], displayNoResult = true, noResult }) => {
  const hasData = isEmpty(data);

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem'}}>
        <CircularProgress />
      </div>
    )
  }

  if (error) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem'}}>
        <img alt="error" style={{ background: 'white' }} src={errorpic} />
      </div>
    )
    
  }

  if (hasData) {
    if (!displayNoResult) return null;
    if (noResult) {
      return noResult;
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img alt="no results" width={200} height={200} src={noresult} />
          <div style={{ fontSize: '1.5rem', color: '#6a79a8' }}>No result found.</div>
        </div>
      )
    }
  }

  return (
    <>
     {children}
    </>
  );
};

export default QueryGuard;
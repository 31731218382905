import gql from "graphql-tag";

export const GET_BOOKING = gql`
   query Booking($id: String) {
     booking(id: $id) {
      id
      hotel {
        id
        name
      }
      packageName
      checkIn
      checkOut
      note
      children
      childrenAge
      adults
      infants
      customer {
        id
        firstName
        lastName
      }
      roomType
      board
      view
      extra
      createdBy
      paperwork {
        confirmation
        voucher
      }
      customerPayment {
        total {
          amount
          paid
          date
          remaining
        }
        deposit {
          amount
          paid
          date
          remaining
        }
        name 
        payments {
          id
          date
          bank
          code
          amount
        }
        notes
      }
      partnerPayment {
        total {
          amount
          paid
          date
          remaining
        }
        deposit {
          amount
          paid
          date
          remaining
        }
        name 
        payments {
          id
          date
          code
          bank
          amount
        }
        notes
      }
      invoice
      reservationDate
    }       
  }
`;


export const GET_BOOKINGS_BY_DATE = gql`
  query bookingsByDate($dateUntil: String, $dateFrom: String, $fieldName: String) {
    allBookings(dateFrom: $dateFrom, dateUntil: $dateUntil, fieldName: $fieldName) {
      id
      hotel {
        id
        name
      }
      packageName
      checkIn
      checkOut
      customer {
        id
        firstName
        lastName
      }
      children
      adults
      infants
      customerPayment {
        total {
          amount
        }
      }
      partnerPayment {
        name
      }
    }
  }
`
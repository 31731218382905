import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { formatMoney } from '../../../../utils/utils';

interface Props {
  code: string;
  amount: number;
  actions?: { label: string, onClick: () => void }[]
}

const GiftCard: FC<Props> = ({ code, amount, actions}) => {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {code}
        </Typography>
        <Typography gutterBottom variant="body1" component="div">
          {formatMoney(amount)}
        </Typography>
      </CardContent>
      {actions && <CardActions>
        {actions.map((action, i) => <Button key={i} onClick={action.onClick} variant='contained' size="small">{action.label}</Button>)}
      </CardActions>}
    </Card>
  )
};

export default GiftCard;
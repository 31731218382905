import React from 'react';
import Payment from '@material-ui/icons/Payment';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import Home from '@material-ui/icons/Home';
import { Avatar, makeStyles } from '@material-ui/core';
import { green, red, blue, orange } from '@material-ui/core/colors';
import { ActionItemTypeEnum, PaymentTypeEnum } from '../../Pages/Customers/types/types';

const useStyles = makeStyles(theme => ({
  missingProforma: {
    color: red[500],
    backgroundColor: 'white',
  },
  total: {
    color: blue[500],
    backgroundColor: 'white',
  },
  deposit: {
    color: orange[500],
    backgroundColor: 'white',
  },
}));

const iconConfig = {
  [PaymentTypeEnum.CUSTOMER]: <Payment />,
  [PaymentTypeEnum.PARTNER]: <SupervisedUserCircleIcon />
}

interface ActionItemAvatarProps {
  type: ActionItemTypeEnum;
  paymentType: PaymentTypeEnum
}

const ActionItemAvatar = ({ type, paymentType }: ActionItemAvatarProps) => {
  const style = useStyles();

  return (
    <Avatar aria-label="name" className={style[type]}>
      {iconConfig[paymentType]}
    </Avatar>
  )
};

export default ActionItemAvatar;
import { CircularProgress, Fab, Switch, makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { useQuery } from '@apollo/react-hooks';
import { ACTIVE_REQUESTS } from './graphql/queries';
import { ActiveRequest } from './types';
import RequestCard from './RequestCard/RequestCard';
import { useUserDetails } from '../../UserContext/UserContex';

interface Props extends RouteComponentProps {

}

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    background: 'white',
    borderColor: 'white',
    marginBottom: '2rem'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  grid: {
    textAlign: 'right',
  },
  fab: {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
  }
}));

const Requests: FC<Props> = ({ history }) => {
  const { fab, header } = useStyles();
  const [filterByMe, setFilterByMe] = useState<boolean>(false);
  const { id } = useUserDetails();
  const adminId = filterByMe ? id : undefined;
  const { data, loading } = useQuery(ACTIVE_REQUESTS, { variables: { adminId }})

  const renderRequests = (activeRequests: ActiveRequest[]) => {
    return activeRequests.map((activeRequest) => <RequestCard key={activeRequest.id} activeRequest={activeRequest} />)
  }


  return (
    <>
      <div className={header}>
        <h1>Active requests</h1>
        <div>
          <span style={{ marginRight: "8px"}}>Filter by me</span><Switch checked={filterByMe} onChange={() => setFilterByMe(!filterByMe)} />

        </div>
      </div>
      {loading && <CircularProgress />}
      {data && data.activeRequests && renderRequests(data.activeRequests)}
      <Fab
        color="primary"
        onClick={() => history.push("/requests/new")}
        aria-label="add"
        className={fab}
      >
        <AddIcon />
      </Fab>

    </>
  )
};

export default Requests;
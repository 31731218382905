import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { drawerWidth } from '../AppContainer/AppContainer';
import { useAuth } from '../AuthContext/AuthContext';
import { Avatar } from '@material-ui/core';
import { useUserDetails } from '../UserContext/UserContex';
import { RouteComponentProps, withRouter } from 'react-router';


const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
}));

interface AppHeaderProps extends RouteComponentProps {
  sidebarOpen: boolean;
  onSidebarOpen: () => void;
}

const AppHeader = ({ sidebarOpen, onSidebarOpen, history }: AppHeaderProps) => {
  const { appBar, appBarShift, menuButton, menuButtonHidden, title, toolbar } = useStyles();
  const { logout } = useAuth();
  const { firstName, lastName } = useUserDetails();

  const handleLogout = () => {
    logout()
  }

  return (
    <AppBar position="absolute" className={clsx(appBar, sidebarOpen && appBarShift)}>
    <Toolbar className={toolbar}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={onSidebarOpen}
        className={clsx(menuButton, sidebarOpen && menuButtonHidden)}
      >
        <MenuIcon />
      </IconButton>
      <Typography component="h1" variant="h6" color="inherit" noWrap className={title}>
        Five Star Travel Services
      </Typography>
      <IconButton color="inherit" onClick={handleLogout}>
        <ExitToAppIcon />
      </IconButton>
      {firstName && lastName && (
        <Avatar>{`${firstName[0]}${lastName[0]}`}</Avatar>
      )}
    </Toolbar>
  </AppBar>
  )
}

export default withRouter(AppHeader);
import React from 'react';

import HotelInfo from '../HotelInfo';
import {  GET_HOTEL } from '../graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { withRouter, RouteComponentProps } from 'react-router';
import useHandleAuthError from '../../../utils/hooks/useHandleAuthError';
import QueryGuard from '../../../Components/QueryGuard';
import { MatchParams } from '../../Customers/types/types';
import { HotelDetails as HotelDetailsType } from '../types';
import { formatMoney, formatMonthDayYear } from '../../../utils/utils';
import Table from '../../../Components/Table';
import { CircularProgress } from '@material-ui/core';


const HotelDetails = ({ match, history } : RouteComponentProps<MatchParams>) => {
  const id = match.params.id;
  const onError = useHandleAuthError();

  const { loading, error, data } = useQuery(GET_HOTEL, {
    variables: { id },
    onError
  });

  const renderBookingTable = (hotel: HotelDetailsType) => {
    const tableData = hotel.bookings.map((booking) => ({
      data: {
        customer: booking.customer ? `${booking.customer.firstName} ${booking.customer.lastName}`: "",
        checkIn: formatMonthDayYear(+booking.checkIn),
        checkOut: formatMonthDayYear(+booking.checkOut),
        total: formatMoney(booking.customerPayment.total.amount)
      },
      onRowClick: () => history.push(`/bookings/${booking.id}`)
    }))

    return (<div style={{ marginTop: '2rem'}}><h2>Bookings</h2><Table tableData={tableData} /></div>);
  }

  if(loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem'}}>
        <CircularProgress />
      </div>
    )
  }

  return data && data.hotel && (
    <>
      <HotelInfo hotel={data.hotel} />
      {data.hotel.bookings.length ? renderBookingTable(data.hotel) : null}
    </>
  )
}

export default withRouter(HotelDetails);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, Card, CardHeader, Avatar } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Customer } from '../types/types';
import { withRouter, RouteComponentProps } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'auto',
  },
  card: {
    marginBottom: theme.spacing(1),
    width: '100%',
    cursor: 'pointer'
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

interface CustomerListProps extends RouteComponentProps {
  customers: Customer[];
}

const CustomerList = ({ customers, history }: CustomerListProps) => {
  const { avatar, root, card } = useStyles();

  return (
    <div className={root}>
      {customers.map((customer, index) => (
        <Card className={card} key={`customer${index}`}>
          <CardHeader
            avatar={
              <Avatar aria-label="name" className={avatar}>
                {`${customer.firstName ? customer.firstName[0]: ''}${customer.lastName? customer.lastName[0] : ''}`}
              </Avatar>
            }
            title={`${customer.firstName} ${customer.lastName}`}
            subheader={customer.email}
            onClick={() => history.push(`/customers/details/${customer.id}`)}
          />
        </Card>
      ))}
    </div>
  );
};

export default withRouter(CustomerList);
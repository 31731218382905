import React from 'react';
import { makeStyles, Paper, Divider } from '@material-ui/core';
import PageHeader from '../../../Components/PageHeader';
import CustomerForm from '../CustomerForm';
import { Customer, MatchParams } from '../types/types';
import { withRouter, RouteComponentProps } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_CUSTOMER, GET_CUSTOMERS } from '../graphql/queries';
import { UPDATE_CUSTOMER } from '../graphql/mutations';
import useNotifications from '../../../Notifications/useNotifications';
import useHandleAuthError from '../../../utils/hooks/useHandleAuthError';

interface EditCustomerProps extends RouteComponentProps<MatchParams> {
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

const EditCustomer = ({ match, history }: EditCustomerProps) => {
  const { paper, header } = useStyles();
  const onError = useHandleAuthError();
  const { addSuccess, addError } = useNotifications();

  const id = match.params.id;

  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { id },
    onError
  });

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, 
    { 
      onCompleted: ({ updateCustomer }) => {
        if(!!updateCustomer) {
          addSuccess('Customer updated successfully.')
          history.push(`/customers/details/${id}`)
        } else {
          addError('Error updating customer. Please try again.')
        }
      },
      refetchQueries: [{ query: GET_CUSTOMER, variables: { id } }, { query: GET_CUSTOMERS }],
      awaitRefetchQueries: true,
    }
  );


  const onSubmit = (values: Customer) => updateCustomer({ 
    variables: { 
      id,
      values : {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        afm: values.afm,
        notes: values.notes
      }
    }
  });

  if (loading) {
    return <div>Loading</div>
  }
  
  if (data && data.customer) {
    return (
      <>
      <Paper className={paper}>
        <PageHeader title="New Customer" className={header}/>
        <Divider variant="fullWidth" />
        <CustomerForm onSubmit={onSubmit} initialValues={data.customer} />
      </Paper>
      </>
    )
  }
  
  return <div>errror</div>
}

export default withRouter(EditCustomer);
import React from 'react';
import { Drawer, IconButton, Divider, List, makeStyles, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import { secondaryListItems, mainList } from './listItems';
import { drawerWidth } from '../AppContainer/AppContainer';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
}));

interface SidebarProps extends RouteComponentProps {
  onClose: () => void;
  isOpened: boolean;
}

const Sidebar = ({ onClose, isOpened, history }: SidebarProps) => {
  const { drawerPaper, drawerPaperClose, toolbarIcon } = useStyles();
  return (
    <Drawer
    variant="permanent"
    classes={{
      paper: clsx(drawerPaper, !isOpened && drawerPaperClose),
    }}
    open={isOpened}
    >
      <div className={toolbarIcon}>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {mainList.map((element, index) => (
          <ListItem key={`listitem${index}`} onClick={() => history.push(element.route)} button>
            <ListItemIcon>
              {element.icon}
            </ListItemIcon>
            <ListItemText primary={element.title} />
          </ListItem>
      ))}
      </List>
    </Drawer>
  )
}

export default withRouter(Sidebar);
import gql from "graphql-tag";

export const LOGIN_ADMIN = gql`
  query Login($input: AdminInput!) {
    login(input: $input) {
      token
      tokenExpiration
      firstName
      lastName
      id
    }
  }
`
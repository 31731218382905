import gql from 'graphql-tag';

export const DELETE_ADMIN = gql`
  mutation deleteAdminUser($adminId: String!, $email: String!) {
    deleteAdminUser(adminId: $adminId, email: $email)
  }
`

export const CREATE_USER = gql`
  mutation createAdminUser($adminId: String!, $firstName: String!, $lastName: String!, $email: String!, $password: String!) {
    createAdminUser(adminId: $adminId, firstName: $firstName, lastName: $lastName, email: $email, password: $password)
  }
`
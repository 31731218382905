import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { FC, useState } from 'react';
import { GET_GIFT_CARDS } from './graphql/queries';
import QueryGuard from '../../Components/QueryGuard';
import { GiftCardType } from './type';
import GiftCard from '../../Components/ApplyGiftCardSection/components/GiftCard/GiftCard';
import { RouterProps, withRouter } from 'react-router';
import { CircularProgress, Grid, TextField } from '@material-ui/core';
import { DELETE_GIFT_VOUCHER } from './graphql/mutations';
import { GET_CUSTOMER } from '../Customers/graphql/queries';
import useNotifications from '../../Notifications/useNotifications';


const GiftVouchers: FC<RouterProps> = ({ history }) => {
  const [search, setSearch] = useState<string>();
  const { addError } = useNotifications()
  const { data, loading } = useQuery(GET_GIFT_CARDS, { variables: { search }})
  const [deleteGiftVoucher] = useMutation(DELETE_GIFT_VOUCHER, { onCompleted: ({ deleteGiftCard }) => !!deleteGiftCard ? history.goBack() : null, onError: () => addError("There was an error deleting this voucher. Please try again later.")})

  const giftCards: GiftCardType[] = (data && data.giftCards) || [];

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearch(e.target.value);
    }
  }

  const handleDelete = async (giftCard: GiftCardType) => {
    await deleteGiftVoucher( { variables: { id: giftCard.id }, refetchQueries: [{ query: GET_GIFT_CARDS }, { query: GET_CUSTOMER, variables: { id: giftCard.customer.id} }] })
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem'}}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <h1 style={{ marginBottom: "2rem"}}>Gift vouchers</h1>
      <TextField
        id="inline-search"
        label="Search"
        placeholder="Search by code..."
        type="search"
        variant="outlined"
        name="search"
        onKeyPress={onKeyPress}
        style={{ width: '100%', marginBottom: '2rem'}}
        margin="normal"
      />
      <Grid container spacing={3}>
        {giftCards.map((giftCard) => {
          const details = {
            label: 'Details',
            onClick: () => history.push(`/gift-vouchers/${giftCard.id}`)
          }
          const getButtonConfig = () => {
            if(giftCard && giftCard.usedOn) {
              const map = {
                booking: `/bookings/${giftCard.usedOn.id}`,
                ticket: `/tickets/${giftCard.usedOn.id}`,
              }

              return [{ label: "See product", onClick: () => history.push(map[giftCard.usedOn.type])}, details]
            }

            return [{ label: 'Delete', onClick: () => handleDelete(giftCard) }, details]
          }
          return <Grid key={giftCard.id} item lg={3} md={4} xs={12} ><GiftCard code={giftCard.code} amount={giftCard.amount} actions={getButtonConfig()} /></Grid>
        })}
      </Grid>
    </>
  )
};

export default withRouter(GiftVouchers);
import { useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';
import QueryGuard from '../../Components/QueryGuard';
import Table from '../../Components/Table';
import { GET_MESSAGE_STASUSES } from './graphql/query';
import moment from 'moment';

const statusMap = {
  accepted: 'Pending',
  delivered: 'Delivered',
  failed: 'Email could not be sent',
  rejected: 'Rejected by the email server'
}

type MessageStatus = {
  event: "accepted" | "delivered" | "failed" | "rejected"
  to: string
  subject: string
  id: string
  timestamp: string
}

const MessageStatuses: FC = () => {
  const { data, loading, error } = useQuery(GET_MESSAGE_STASUSES);

  const renderTable = (messageStatuses: MessageStatus[]) => {
    const tableData =  messageStatuses.map((status) => ({
      data: {
        time: moment(status.timestamp).format("D MMM, YYYY, HH:mm"),
        recipient: status.to,
        subject: status.subject,
        status: statusMap[status.event]
      }
    }))
    return <Table tableData={tableData} />
  }

  return (
    <>
      <h1 style={{ marginBottom: '4rem' }}>Message statuses</h1>
      <QueryGuard data={data} loading={loading} error={!!error}>
        {data && data.messageStatuses && renderTable(data.messageStatuses)}
      </QueryGuard>
    </>
  );
};

export default MessageStatuses;
import React, { FC, useState } from 'react';
import { useUserDetails } from '../UserContext/UserContex';
import AuthContext from './AuthContext';

const AuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const token = localStorage.getItem('accessToken');

    if (!token || token === '') {
      return false;
    } else {
      return true
    }
  });
  const { unsetUser: unsetUserName } = useUserDetails()
  
  const login = (token: string) => {
    localStorage.setItem('accessToken', token);
    setIsAuthenticated(true);
  }

  const logout = () => {
    localStorage.removeItem('accessToken');
    setIsAuthenticated(false);
    unsetUserName()
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout}}>
      {children}
    </AuthContext.Provider>
  )
};

export default AuthProvider;

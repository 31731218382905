import gql from "graphql-tag";

export const GET_TICKET = gql`
  query ticket($id: String!) {
    ticket(id: $id) {
      id
      date
      company
      partner
      numberOfPassengers
      createdAt
      customer {
        id
        firstName
        lastName
      }
      partnerPayment {
        name
        notes
        total {
          amount
          date
          paid
          remaining
        }
        payments {
          id
          amount
          date
          code
          bank
        }
        deposit {
          amount
          remaining
          date
          paid
        }
      }
      customerPayment {
        name
        notes
        total {
          amount
          date
          paid
          remaining
        }
        payments {
          id
          amount
          date
          code
          bank
        }
        deposit {
          amount
          remaining
          date
          paid
        }
      }
    }
  }
`
import React from 'react';
import { Formik, FormikProps, Form } from 'formik';
import { Grid, TextField, Button, makeStyles, FormControl } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import * as Yup from 'yup';
import AutoSuggest, { OptionType } from './AutoSuggest';
import { Hotel } from '../../Customers/types/types';
import { GET_HOTEL_LABEL_FIELDS } from '../../Customers/graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import useHandleAuthError from '../../../utils/hooks/useHandleAuthError';
import Select from '../../../Components/Select';
import { ROOM_TYPES } from '../../OperationsSettings/RoomTypes/graphql/queries';

export interface BookingValues {
  packageName?: string;
  checkIn: Date;
  checkOut: Date;
  adults: number;
  children: number;
  infants: number;
  roomType: string;
  hotel: string;
  note: string;
  board: string;
  childrenAge?: string;
  total: number;
  partner?: string;
  partnerTotal?: number;
  view: string;
  extra: string;
}

interface Props {
  initialValues?: BookingValues;
  onSubmit: (value: BookingValues) => void;
  submitLabel?: string;
}

const bookingSchema = Yup.object().shape({
  hotel: Yup.string()
    .required('Required'),
});

const defaultValues: BookingValues = {
  checkIn: new Date(),
  checkOut: new Date(),
  adults: 0,
  children: 0,
  infants: 0,
  roomType: '',
  hotel: '',
  packageName: undefined,
  partner: undefined,
  childrenAge: undefined,
  note: '',
  board: '',
  total: 0,
  partnerTotal: 0,
  view: '',
  extra: '',
}

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    marginBottom: '10rem'
  },
  title: {
    paddingBottom: '0rem',
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  form: {
    marginTop: theme.spacing(2),
  },
  hotel: {
    display: 'flex',
    alignItems: 'center'
  },
  hotelBtn: {
    marginLeft: '2rem',
    fontSize: '1.5rem',
    height: '100%'
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: '25rem',
    marginTop: '8rem'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '50%',
    padding: theme.spacing(2, 4, 3),
  },
  customGrid: {
    paddingBottom: '0rem !important',
    paddingTop: '0rem !important'
  }
}));

const BookingForm = ({ onSubmit, initialValues = defaultValues, submitLabel = 'Create booking' }: Props) => {
  const { form, input, button, hotel, title, customGrid } = useStyles();
  const [suggestions, setSuggestions] = React.useState<OptionType[]>([]);
  const onError = useHandleAuthError();
  const { data: roomTypesData } = useQuery(ROOM_TYPES);
  const roomTypes = roomTypesData && roomTypesData.roomTypes || [];

  const { data } = useQuery(GET_HOTEL_LABEL_FIELDS, { onError });

  const createSuggestItems = (hotels: Hotel[]): OptionType[] => hotels.map((hotel) => ({ label: hotel.name, value: hotel.id }))

  if (data && data.hotels && data.hotels.length > 0 && !suggestions.length) { setSuggestions(createSuggestItems(data.hotels)) };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={bookingSchema}
    >
      {({ errors, touched, handleChange, handleBlur, values, setFieldValue }: FormikProps<BookingValues>) => (
        <Form className={form}>
          <Grid container spacing={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <>
                <Grid className={customGrid} item xs={12}><div className={title}>Hotel</div></Grid>
                <Grid className={hotel} item xs={12}>
                  <AutoSuggest 
                    name="hotel"
                    onChange={(value) => setFieldValue('hotel', value)}
                    suggestions={suggestions}
                    placeholder="Search for hotel"
                    value={values.hotel}
                    label="Hotel"
                  />
                </Grid>
              </>

              <Grid item xs={12}>
                <TextField
                  id="outlined-name"
                  label="Package name"
                  name="packageName"
                  value={values.packageName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(!!errors.packageName && !!touched.packageName)}
                  helperText={(!!errors.packageName && !!touched.packageName) && errors.packageName}
                  margin="normal"
                  className={input}
                  variant="outlined"
                />
              </Grid>
            <Grid className={customGrid} item xs={12}><div className={title}>Dates</div></Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  format="dd/MM/yyyy"
                  onFocus={() => {}}
                  margin="normal"
                  id="date-picker-inline"
                  label="Check In"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  name="checkIn"
                  inputVariant="outlined"
                  value={values.checkIn}
                  onChange={(date) => setFieldValue('checkIn', date)}
                  onBlur={handleBlur}
                  error={(!!errors.checkIn && !!touched.checkIn)}
                  helperText={(!!errors.checkIn && !!touched.checkIn) && errors.checkIn}
                  className={input}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  format="dd/MM/yyyy"
                  margin="normal"
                  onFocus={() => {}}
                  id="date-picker-inline"
                  label="Check Out"
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minDate={values.checkIn}
                  name="checkOut"
                  value={values.checkOut}
                  onChange={(date) => setFieldValue('checkOut', date)}
                  onBlur={handleBlur}
                  error={(!!errors.checkOut && !!touched.checkOut)}
                  helperText={(!!errors.checkOut && !!touched.checkOut) && errors.checkOut}
                  className={input}
                />
              </Grid>
            
            <Grid className={customGrid} item xs={12}><div className={title}>Guest details</div></Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-number"
                label="Adults"
                name="adults"
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
                value={values.adults}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.adults && !!touched.adults)}
                helperText={(!!errors.adults && !!touched.adults) && errors.adults}
                margin="normal"
                className={input}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-name"
                label="Children"
                name="children"
                type="number"
                value={values.children}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.children && !!touched.children)}
                helperText={(!!errors.children && !!touched.children) && errors.children}
                margin="normal"
                className={input}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-name"
                label="Infants"
                name="infants"
                type="number"
                value={values.infants}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(!!errors.infants && !!touched.infants)}
                helperText={(!!errors.infants && !!touched.infants) && errors.infants}
                margin="normal"
                className={input}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
                <TextField
                  id="outlined-name"
                  label="Children age"
                  name="childrenAge"
                  value={values.childrenAge}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(!!errors.childrenAge && !!touched.childrenAge)}
                  helperText={(!!errors.childrenAge && !!touched.childrenAge) && errors.childrenAge}
                  margin="normal"
                  className={input}
                  variant="outlined"
                />
              </Grid>
            <Grid className={customGrid} item xs={12}><div className={title}>Room details</div></Grid>
            <Grid item xs={4}>
              <FormControl className={input}>
                <Select
                  value={values.roomType}
                  label="Room type"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('roomType', event.target.value)}
                  options={roomTypes.map((roomType) => ({ value: roomType, name: roomType }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl className={input}>
                <Select
                  value={values.view}
                  label="View"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('view', event.target.value)}
                  options={[
                    { value: 'garden', name: 'Garden' },
                    { value: 'sea', name: 'Sea' },
                    { value: 'side sea', name: 'Side sea' },
                    { value: 'mountain', name: 'Mountain' },
                    { value: 'pool', name: 'Pool' },
                  ]}
                />

              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={input}>
                <Select
                  name='board'
                  onBlur={handleBlur}
                  label="Board"
                  value={values.board}
                  onChange={(event) => setFieldValue('board', event.target.value)}
                  options={[
                    { value : 'room only', name: 'Room Only' },
                    { value : 'full board', name: 'Full board' },
                    { value : 'half board', name: 'Half board' },
                    { value : 'full board + drinks', name: 'Full board + drinks' },
                    { value : 'half board + drinks', name: 'Half board + drinks' },
                    { value : 'bed + breakfast', name: 'Bed + breakfast' },
                    { value : 'smart all inclusive', name: 'Smart all inclusive' },
                    { value : 'all inclusive', name: 'All inclusive' },
                    { value : 'ultra all inclusive', name: 'Ultra all inclusive' },
                    { value : 'premium all inclusive', name: 'Premium all inclusive' },
                    { value : 'soft all inclusive', name: 'Soft all inclusive' },
                    { value : 'light all inclusive', name: 'Light all inclusive' }
                  ]}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl className={input}>
                <Select
                  onBlur={handleBlur}
                  value={values.extra}
                  onChange={(event) => setFieldValue('extra', event.target.value)}
                  label="Extra"
                  options={[
                    { name: 'Extra Bed', value: 'bed' }, 
                    { name: 'Extra cot', value: 'cot' },
                    { name: 'Bunk Bed', value: 'bunk bed' },
                    { name: 'Cot + Bed', value: 'cot + bed' }
                  ]}
                />
              </FormControl>
            </Grid>
            <Grid className={customGrid} item xs={12}><div className={title}>Payments</div></Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-number"
                label="Total"
                value={values.total}
                name="total"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                className={input}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea"
                label="Partner agency"
                value={values.partner}
                name="partner"
                onChange={handleChange}
                onBlur={handleBlur}
                className={input}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea"
                label="Partner payment amount"
                value={values.partnerTotal}
                name="partnerTotal"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                className={input}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid className={customGrid} item xs={12}><div className={title}>Notes</div></Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-textarea"
                label="Note"
                value={values.note}
                name="note"
                onChange={handleChange}
                onBlur={handleBlur}
                className={input}
                multiline
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </MuiPickersUtilsProvider>
            <Grid item xs={12}>
              <Button variant="contained" className={button} type="submit" color="primary">
                {submitLabel}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default BookingForm;
import gql from "graphql-tag";

export const UPDATE_BOOKING = gql`
  mutation updateBooking($id: ID!, $values: BookingInput) {
    updateBooking(id: $id, values: $values) 
  }
`;

export const DELETE_PAYMENT = gql`
  mutation deletePayment($bookingId: ID!, $transactionId: String, $paymentType: String) {
    deletePayment(bookingId: $bookingId, transactionId: $transactionId, paymentType: $paymentType)
  }
`;

export const ADD_PAYMENT_NOTES = gql`
  mutation deletePayment($id: ID!, $paymentType: String, $notes: String) {
    addPaymentNotes(id: $id, paymentType: $paymentType, notes: $notes)
  }
`;
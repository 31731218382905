import React, { FC } from 'react';
import { Booking } from '../../Customers/types/types';
import { RouteComponentProps, withRouter } from 'react-router';
import BookingForm from '../BookingForm';
import { BookingValues } from '../BookingForm/BookingForm';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_BOOKING } from '../graphql/mutations';
import { dateFormat } from '../../../utils/utils';
import { BookingInput } from '../types';
import useNotifications from '../../../Notifications/useNotifications';
import { GET_BOOKING } from '../graphql/queries';

// TODO - return hotel id to fill the initialvalue

interface Props extends RouteComponentProps<{}, {}, { booking: Booking }> {

}

const EditBooking: FC<Props> = ({ location, history }) => {
  const { state } = location;
  const { booking } = state;
  console.log(booking)

  const { addSuccess, addError } = useNotifications();
  const [updateBooking] = useMutation(UPDATE_BOOKING, {
    onError: () => addError('There was an error editing this booking.'),
    onCompleted: () => {
      addSuccess('Booking updated.')
      history.push(`/bookings/${booking.id}`);
    },
    refetchQueries: [{ query: GET_BOOKING, variables: { id: booking.id }}],
    awaitRefetchQueries: true
  })

  const onSubmit = async (values: BookingValues) => {
    const checkIn = moment(values.checkIn).format(dateFormat);
    const checkOut = moment(values.checkOut).format(dateFormat);

    const paperwork = {
      voucher: booking.paperwork.voucher,
      confirmation: booking.paperwork.confirmation,
      invoice: booking.paperwork.invoice
    }

    const input: BookingInput = {
      checkIn,
      checkOut, 
      packageName: values.packageName,
      reservationDate: booking.reservationDate,
      customerId: booking.customer.id,
      paperwork,
      adults: values.adults,
      children: values.children,
      infants: values.infants,
      roomType: values.roomType,
      hotel: values.hotel,
      note: values.note,
      childrenAge: values.childrenAge,
      board: values.board,
      extra: values.extra,
      view: values.view,
      total: values.total? +values.total : 0,
      partner: values.partner,
      partnerTotal: values.partnerTotal ? +values.partnerTotal : 0
    }

    await updateBooking({ variables: { id: booking.id, values: input }})
  };

  const initialValues: BookingValues = {
    ...booking,
    hotel: booking.hotel.id,
    checkIn: new Date(+booking.checkIn),
    checkOut: moment(+booking.checkOut).toDate(),
    partner: booking.partnerPayment.name,
    partnerTotal: booking.partnerPayment.total.amount,
    total: booking.customerPayment.total.amount
  }

  return (
    <>
      <div style={{ padding: '2rem 0', fontWeight: 700, fontSize: '1.5rem' }}>Edit booking</div>
      <BookingForm submitLabel="Update booking" onSubmit={onSubmit} initialValues={initialValues} />
    </>
  );
};

export default withRouter(EditBooking);
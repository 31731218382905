import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { MatchParams } from '../../Customers/types/types';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { GET_CUSTOMER, GET_ACTION_ITEMS, GET_CUSTOMER_TICKETS } from '../../Customers/graphql/queries';
import useNotification from '../../../Notifications/useNotifications';
import { CREATE_GIFT_VOUCHER } from '../graphql/mutations';
import { GiftVoucherInput } from '../type';
import GiftVoucherForm from '../GiftVoucherForm/GiftVoucherForm';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(8),
  },
}));

const NewGiftVoucher = ({ match, history }: RouteComponentProps<MatchParams>) => {
  const id = match.params.id;
  const { addSuccess, addError } = useNotification();
  const { header } = useStyles();

  const [createGiftVoucher] = useMutation(CREATE_GIFT_VOUCHER, 
    { 
      refetchQueries: [{ query: GET_CUSTOMER, variables: { id } }, { query: GET_ACTION_ITEMS }, { query: GET_CUSTOMER_TICKETS, variables: { customerId: id }}],
      awaitRefetchQueries: true,
      onCompleted: ({ createGiftCard }) => {
        if(!!createGiftCard) {
          addSuccess('New gift voucher created successfully');
          history.push(`/customers/details/${id}`)
        } else {
          addError('Error creating gift voucher. Please try again.')
        }
      },
      onError: (error) => addError(error.message) 
    },
  ); 

  const onSubmit = async (input: GiftVoucherInput) => {

    const values = {
      customerId: id,
      amount: input.amount,
      code: input.code
    }
    await createGiftVoucher({ variables: { values }})
  };
  
  return (
    <>
      <>
        <h1 className={header}>New gift voucher</h1>
        <GiftVoucherForm onSubmit={onSubmit} />
      </>
    </>
  )
};

export default withRouter(NewGiftVoucher);
import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { formatMoney } from '../../../../utils/utils';
import QueryGuard from '../../../../Components/QueryGuard';
import Table from '../../../../Components/Table';
import { GET_GIFT_CARDS } from '../../../GiftVouchers/graphql/queries';
import { GiftCardType } from '../../../GiftVouchers/type';

interface Props extends RouteComponentProps {
  customerId: string;
}

const CustomerTickets: FC<Props> = ({ history, customerId }) => {
  const { data, error, loading } = useQuery(GET_GIFT_CARDS, { variables: { customerId }});

  const renderTicketTable = (giftVoucher: GiftCardType[]) => {
    const tableData = giftVoucher.map((giftVoucher) => ({
      data: {
        code: giftVoucher.code,
        amount: formatMoney(giftVoucher.amount || 0)
      },
      onRowClick: () => history.push(`/gift-vouchers/${giftVoucher.id}`)
    }))

    return (<Table tableData={tableData} />);
  }

  return (
    <QueryGuard data={data && data.giftCards} loading={loading} error={error ? !!error : false} displayNoResult={false}>
        {data && data.giftCards && 
          <div>
            <h2>Gift vouchers</h2>
            <div>
              {renderTicketTable(data.giftCards)}
            </div>
        </div>
      }
    </QueryGuard>
  );
};

export default withRouter(CustomerTickets);

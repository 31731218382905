import { useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GET_GIFT_CARDS, GET_GIFT_VOUCHER_DETAILS } from '../graphql/queries';
import { PaymentTypeEnum, Transaction } from '../../Customers/types/types';
import { formatMoney, formatMonthDayYear } from '../../../utils/utils';
import { makeStyles, Divider, IconButton, Modal, Backdrop, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachmentIcon from '@material-ui/icons/Attachment';

import { useMutation } from '@apollo/react-hooks';
import useNotifications from '../../../Notifications/useNotifications';
import { GET_ACTION_ITEMS, GET_CUSTOMER } from '../../Customers/graphql/queries';
import QueryGuard from '../../../Components/QueryGuard';
import PaymentDetails from '../../Bookings/BookingDetails/Payment/PaymentDetails';
import { ADD_GIFT_CARD_PAYMENT_NOTE, DELETE_GIFT_VOUCHER, DELETE_GIFT_VOUCHER_PAYMENT, MAKE_GIFT_VOUCHER_PAYMENT } from '../graphql/mutations';
import { GiftCardType } from '../type';
import { Link } from 'react-router-dom';

interface Props extends RouteComponentProps<{ id: string }> {
  
}

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  header: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    marginTop: '2rem'
  },
}));

const GiftVoucherDetails: FC<Props> = ({ match, history }) => {
  const { id } = match.params;
  const { data, loading, error } = useQuery(GET_GIFT_VOUCHER_DETAILS, { variables: { id }, fetchPolicy: 'cache-and-network' })
  const { row, header } = useStyles();
  const { addSuccess, addError } = useNotifications();

  const [deletePayment] = useMutation(DELETE_GIFT_VOUCHER_PAYMENT, {
    onCompleted: () => addSuccess('Payment removed'),
    onError: () => addError('There was an error removing this payment'),
    refetchQueries: [{ query: GET_GIFT_VOUCHER_DETAILS, variables: { id } }]
  });

  const [addNotes] = useMutation(ADD_GIFT_CARD_PAYMENT_NOTE, {
    onCompleted: () => addSuccess('Note has been added'),
    onError: () => addError('There was an error adding notes to this payment'),
    refetchQueries: [{ query: GET_GIFT_VOUCHER_DETAILS, variables: { id } }]
  });

  const [deleteGiftVoucher] = useMutation(DELETE_GIFT_VOUCHER, { onCompleted: ({ deleteGiftCard }) => !!deleteGiftCard ? history.goBack() : null, onError: () => addError("There was an error deleting this voucher. Please try again later.")})

  const [makePayment] = useMutation(MAKE_GIFT_VOUCHER_PAYMENT, {
    onCompleted: ({ makeGiftCardPayment }) => {
      if (!!makeGiftCardPayment) {
        addSuccess('Payment fields updated successfully');
      } else {
        addError('Error updating payment fields. Please try again.')
      } 
    },
    refetchQueries: [{ query: GET_ACTION_ITEMS }, { query: GET_GIFT_VOUCHER_DETAILS, variables: { id } }],
    awaitRefetchQueries: true,
  });

  const handleUpdateNotes = async (notes: string, ) => {
    await addNotes({ variables: { id, notes }})
  }

  const handleAddPayment = async (transaction: Transaction) => {
    await makePayment({ variables: { id: id, transaction }})
  }

  const handleDeletePayment = async (transactionId: string) => {
    await deletePayment({ variables: { id, transactionId }});
  }

  const renderActions = (giftCard: GiftCardType) => {
      if(giftCard.usedOn) {
        const map = {
          booking: `/bookings/${giftCard.usedOn.id}`,
          ticket: `/tickets/${giftCard.usedOn.id}`,
        }

        return <IconButton onClick={() => history.push(map[giftCard.usedOn.type])} aria-label="delete">
        <AttachmentIcon />
      </IconButton>
      }
      
      return (
        <IconButton onClick={() => { deleteGiftVoucher( { variables: { id: giftCard.id }, refetchQueries: [{ query: GET_GIFT_CARDS }, { query: GET_CUSTOMER, variables: { id: giftCard.customer.id } }] })}}>
          <DeleteIcon />
        </IconButton>
      )
  }

  const renderTicketDetails = () => {
    const giftCard: GiftCardType = data.getGiftCard;
    console.log(giftCard);
    return (
  <>
    <div className={header}>
      <div>
        <div className={row}><b>Code:</b> {giftCard.code}</div>
        <div className={row}><b>Amount:</b> {formatMoney(giftCard.amount)}</div>
        <div className={row}><b>Customer name:</b>{giftCard.customer ? <Link to={`/customers/${giftCard.customer.id}`}>{` ${giftCard.customer.lastName} ${giftCard.customer.firstName}`.toUpperCase()}</Link> : null}</div>
      </div>
      <div>
        {renderActions(giftCard)}
      </div>
    </div>
    <Divider />
      {giftCard.customerPayment && giftCard.customerPayment.total.amount ? <PaymentDetails  shouldShowAction={() => !(giftCard.usedOn && giftCard.usedOn.id)} payment={giftCard.customerPayment} title="Customer payments" handleAddNote={(notes) => handleUpdateNotes(notes)} handleAddPayment={(newTransaction) => handleAddPayment({...newTransaction, type: PaymentTypeEnum.CUSTOMER}) } handleDeletePayment={(transactionId) => handleDeletePayment(transactionId)}  /> : null}
    </>
  )}

  return (
    <>
      <QueryGuard loading={loading} error={!!error} data={data && data.getGiftCard}>
        {data && data.getGiftCard && renderTicketDetails()}  
      </QueryGuard>
    </>
  );
};

export default withRouter(GiftVoucherDetails);




import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Booking } from '../../types/types';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useQuery } from '@apollo/react-hooks';
import { GET_CUSTOMER_BOOKINGS } from '../../graphql/queries';
import { formatMonthDayYear } from '../../../../utils/utils';
import QueryGuard from '../../../../Components/QueryGuard';
import Table from '../../../../Components/Table';

interface Props extends RouteComponentProps {
  bookingIds: string[];
  customerId: string;
  customerName?: string;
}

const CustomerBookings: FC<Props> = ({ bookingIds, history, customerId }) => {
  const { data, error, loading } = useQuery(GET_CUSTOMER_BOOKINGS, { variables: { ids: bookingIds }});
  
  const renderBookingTable = (bookings: Booking[]) => {
    const tableData = bookings.map((booking) => ({
      data: {
        hotel: booking.hotel? booking.hotel.name : booking.packageName,
        checkIn: formatMonthDayYear(+booking.checkIn),
        checkOut: formatMonthDayYear(+booking.checkOut)
      },
      onRowClick: () => history.push(`/bookings/${booking.id}`)
    }))

    return (<Table tableData={tableData} />);
  }

  return (
    <QueryGuard data={data && data.bookings} loading={loading} error={error ? !!error : false} displayNoResult={false}>
        {data && data.bookings && 
          <div>
            <h2>Bookings</h2>
            <div>
              {renderBookingTable(data.bookings)}
            </div>
        </div>
      }
    </QueryGuard>
  );
};

export default withRouter(CustomerBookings);

import React from 'react';

interface INotificationContext {
  addSuccess: (text: string) => void;
  addError: (text?: string) => void;
}

const initialContext = {
  addSuccess: (text: string) => {},
  addError: (text?: string) => {}
};

const NotificationContext = React.createContext<INotificationContext>(initialContext);

export default NotificationContext;
import React from 'react';
import { makeStyles, Grid, Divider, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import ActionItem from './ActionItem/ActionItem';
import { GET_ACTION_ITEMS } from '../Customers/graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { IActionItem } from '../Customers/types/types';
import useHandleAuthError from '../../utils/hooks/useHandleAuthError';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '1rem',
  },
  header: {
    paddingBottom: '2rem',
    textAlign: 'right',
  },
  divider: {
    marginBottom: '1rem'
  }
}));

const DashBoard = () => {
  const { divider } = useStyles();
  const onError = useHandleAuthError()
  const { loading, error, data } = useQuery(GET_ACTION_ITEMS, { 
    onError,
    fetchPolicy: 'no-cache'
  });

  console.log(data, error, error && error.message);

  if (data && data.actionItems) {
    const { actionItems } = data; 
    const nextSevenDaysActionItems = actionItems.filter((item: IActionItem) => moment().isBefore(+item.date, 'day'));
    const todaysActionItems = actionItems.filter((item: IActionItem) => moment().isSameOrAfter(+item.date, 'day'));
    
    return (
      <div>
        <h1>Notifications</h1>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h2>Today</h2>
            <Divider  className={divider} />
            {todaysActionItems.map((item: IActionItem, i: number) => <ActionItem item={item} key={i}/>)}
          </Grid>
          <Grid item xs={6}>
            <h2>Next 10 days</h2>
            <Divider  className={divider}/>
            {nextSevenDaysActionItems.map((item: IActionItem, i: number) => <ActionItem item={item} key={i}/>)}
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <div>
      {loading && <CircularProgress />}
      {error && <div>Error</div>}
    </div>
  )
};

export default DashBoard;
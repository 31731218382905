import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { Route } from 'react-router-dom';
import Customers from '../Pages/Customers';
import NewCustomer from '../Pages/Customers/NewCustomer';
import CustomerDetails from '../Pages/Customers/CustomerDetails';
import EditCustomer from '../Pages/Customers/EditCustomer';
import NewBooking from '../Pages/Bookings/NewBooking';
import NewTicket from '../Pages/Tickets/NewTicket'
import DashBoard from '../Pages/DashBoard/DashBoard';
import Receipt from '../Pages/Receipt/Receipt';
import BookingDetails from '../Pages/Bookings/BookingDetails';
import Bookings from '../Pages/Bookings/Bookings';
import EditBooking from '../Pages/Bookings/EditBooking';
import ReceiptDetails from '../Pages/Receipt/ReceiptDetails/ReceiptDetails';
import NewReceipt from '../Pages/Receipt/NewReceipt';
import EditReceipt from '../Pages/Receipt/EditReceipt';
import MessageStatuses from '../Pages/MessageStatuses';
import Requests from '../Pages/Requests/Requests';
import NewRequest from '../Pages/Requests/NewRequest/NewRequest';
import AddUser from '../Pages/OperationsSettings/Admins/AddUser/AddUser';
import Hotels from '../Pages/Hotels';
import HotelDetails from '../Pages/Hotels/HotelDetails/HotelDetails';
import NewHotel from '../Pages/Hotels/NewHotel/NewHotel';
import EditHotel from '../Pages/Hotels/EditHotel/EditHotel';
import OperationsSettings from '../Pages/OperationsSettings/OperationsSettings';
import RoomTypes from '../Pages/OperationsSettings/RoomTypes/RoomTypes';
import EditRequest from '../Pages/Requests/EditRequest/EditRequest';
import Admins from '../Pages/OperationsSettings/Admins/Admins';
import TicketDetails from '../Pages/Tickets/TicketDetails/TicketDetails';
import GiftVouchers from '../Pages/GiftVouchers/GiftVouchers';
import GiftVoucherDetails from '../Pages/GiftVouchers/GiftVoucherDetails/GiftVoucherDetails';
import NewGiftVoucher from '../Pages/GiftVouchers/NewGiftVoucher/NewGiftVoucher';
import Analytics from '../Pages/Analytics/Analytics';

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: 'white'
  },
  container: {
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    height: 240,
  },
}));

const MainContent = () => {
  const { content, appBarSpacer, container } = useStyles();

  return (
    <main className={content}>
    <div className={appBarSpacer} />
    <Container maxWidth="lg" className={container}>
      <Route exact path="/bookings/edit/:id" component={EditBooking} />
      <Route exact path="/receipts/:id" component={ReceiptDetails} />
      <Route exact path="/receipts/edit/:id" component={EditReceipt} />
      <Route path="/customers/details/:id" component={CustomerDetails} />
      <Route path="/customers/edit/:id" component={EditCustomer} />
      <Route path="/customers/new" component={NewCustomer} />
      <Route path="/bookings/new/:id" component={NewBooking} />
      <Route path="/tickets/new/:id" exact={true} component={NewTicket} />
      <Route path="/tickets/:id" exact={true} component={TicketDetails} />
      <Route path="/hotels/details/:id" component={HotelDetails} />
      <Route path="/hotels/new" component={NewHotel} />
      <Route path="/hotels/edit/:id" component={EditHotel} />
      <Route path="/operations/room-types" component={RoomTypes} />
      <Route exact path="/operations/admins" component={Admins} />
      <Route exact path="/operations/admins/new" component={AddUser} />
      <Route exact path="/bookings/:id" component={BookingDetails} />
      <Route exact path="/bookings" component={Bookings} />
      <Route exact path="/receipts/new" component={NewReceipt} />
      <Route exact path="/receipts" component={Receipt} />
      <Route exact path="/customers" component={Customers} />
      <Route exact path="/messages" component={MessageStatuses} />
      <Route exact path="/requests/new" component={NewRequest} />
      <Route exact path="/requests/edit/:id" component={EditRequest} />
      <Route exact path="/requests" component={Requests} />
      <Route exact path="/hotels" component={Hotels} />
      <Route exact path="/gift-vouchers" component={GiftVouchers} />
      <Route exact path="/gift-vouchers/:id" component={GiftVoucherDetails} />
      <Route exact path="/gift-vouchers/new/:id" component={NewGiftVoucher} />
      <Route exact path="/operations" component={OperationsSettings} />
      <Route exact path="/analytics" component={Analytics} />
      <Route exact path="/" component={DashBoard} />
    </Container>
  </main>
  )
}

export default MainContent;
import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { startCase, uniqueId } from 'lodash';
import TableComponent from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

interface TableData<T> {
  onRowClick?: () => void;
  data: T;
}

interface Props<T> {
  tableData: TableData<T>[];
  size?: "small" | undefined;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    background: 'white'
  },
  row: {
    "&:hover": { 
      backgroundColor: '#fafafa',
      cursor: 'pointer'
    }
  }
});

function Table<T = any> ({ tableData, size }: Props<T>): ReactElement {
  const { table, row } = useStyles();
  const headers = tableData[0] ? Object.keys(tableData[0].data) : [];
  console.log(tableData)
  return (
    <TableContainer component={Paper}>
      <TableComponent className={table} size={size} aria-label="simple table">
        <TableHead style={{ background: '#f5f5f5' }}>
          <TableRow>
            {headers.map((header: string, i: number) => {
              if (i === 0) {
                return (
                  <TableCell>{startCase(header)}</TableCell>
                )
              } else {
                return (
                <TableCell align="right">{startCase(header)}</TableCell>
                )
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((item, i) => (
              <TableRow onClick={item.onRowClick} className={item.onRowClick ? row: ''} key={uniqueId()}>
                {console.log(item)}
                {headers.map((header: string, i: number) => {
                  if (i === 0) {
                    return (
                      <TableCell component="th" scope="row">
                        {console.log(item.data[header])}
                        {item.data[header]}
                      </TableCell>
                    )
                  } else {
                    return (
                      <TableCell align="right">{item.data[header]}</TableCell>
                    )
                  }
                })}
              </TableRow>
          ))}
        </TableBody>
      </TableComponent>
    </TableContainer>
  );
};

export default Table;
import { useMutation } from '@apollo/react-hooks';
import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import useNotifications from '../../../Notifications/useNotifications';
import { UPDATE_INVOICE } from '../graphql/mutations';
import { GET_INVOICES } from '../graphql/queries';
import ReceiptForm from '../ReceiptForm';
import { Receipt } from '../types';

interface Props extends RouteComponentProps<{ id: string }> {

}

const EditReceipt: FC<Props> = ({ match, history, location }) => {
  const { id } = match.params;
  const { state } = location;
  const receipt = state.receipt;
  const { __typename, ...rest } = receipt;
  const [updateInvoice] = useMutation(UPDATE_INVOICE, {
    onError: () => addError('Something went wrong, please try again.'),
    onCompleted: () => { 
      addSuccess('Invoice updated');
      history.push('/receipts');
    },
    refetchQueries: [{ query: GET_INVOICES }]
  });
  const { addError, addSuccess } = useNotifications();

  const onSubmit = async (values: Receipt) => {
    try {
      const result = await updateInvoice({ variables: {
        id,
        values
      }})
      console.log(result)
    } catch(e) {
      console.log(e)
    }
  }
  return (
    <ReceiptForm title="Edit receipt" initialValues={rest} onSubmit={onSubmit}/>
  );
};

export default withRouter(EditReceipt);
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import React, { FC } from 'react';
import { useState } from 'react';
import Select from '../../../../Components/Select';
import {  Transaction } from '../../../Customers/types/types';

const banks = ['Alpha bank', 'Eurobank', 'National Bank of Greece', 'Piraeus Bank', 'Cash'];
const useStyles = makeStyles(theme => ({
  options: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    display: 'flex',
    marginBottom: theme.spacing(1),
  }
}));

interface Props {
  handleAddPayment: (transaction: Omit<Transaction, 'type'>) => void;
  remaining: number;
  onComplete: () => void;
}

const PaymentForm: FC<Props> = (props) => {
  const { options } = useStyles();
  const { handleAddPayment, remaining, onComplete } = props;

  const [ inputValue, setInputValue ] = useState<number>(0);
  const [ checked, setChecked ] = useState<boolean>(false);
  const [ bank, setBank ] = useState<string>('');

  const handleSubmit = () => {
    const inputAmount = checked ? remaining : inputValue;
    const amount = inputAmount > remaining ? remaining : inputAmount;
    handleAddPayment({ date: moment().toISOString(), amount, bank }); 
    setInputValue(0);
    setChecked(false);
    onComplete();
  }
  
  return (
    <div className={options}>
      <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
        <Select
            value={bank}
            style={{ width: '75%' }}
            label="Bank"
            onChange={(event) => {
              if (event.preventDefault) { event.preventDefault(); }
              setBank(String(event.target.value))}
            }
            options={banks.map((bankItem) => ({ name: bankItem, value: bankItem }))}
          />
          
        <div style={{ display: "flex", alignItems: 'center'}}>
          <TextField
            id="standard-textarea"
            style={{ width: '75%', marginRight: '1rem'}}
            variant="outlined"
            label="Payment"
            value={inputValue}
            type="number"
            disabled={checked}
            onChange={(e) => {
              setInputValue(+e.target.value);
            }}
          />
          <Button 
            style={{ margin: '1.5rem 0', border: '2px solid white' }} 
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
              Payment
          </Button>
        </div>
        <FormControlLabel 
          control={
            <Checkbox
              value={checked} 
              onChange={() => setChecked(!checked)} 
            />
          } 
          label="Total"
        />
      </div>
    </div>
  );
};

export default PaymentForm;
import gql from "graphql-tag";

export const GET_CUSTOMERS = gql`
  query Customers($query: String) {
    customers(query: $query) {
      firstName
      lastName
      email
      id
    }
  }
`;

export const GET_CUSTOMER = gql`
   query Customer($id: String) {
     customer(id: $id) {
      firstName
      lastName
      phone
      email
      id
      bookings
      review
      afm
      notes
    }       
  }
`;

export const GET_BOOKINGS = gql`
  query Bookings($ids: [String]) {
    bookings(ids: $ids) {
      id
      hotel {
        name
      }
      checkIn
      checkOut
      note
      children
      adults
      infants
      customerId
      roomType
      board
      view
      paperwork {
        confirmation
        voucher
      }
      total {
        amount
        paid
        date
        fullPayment
      }
      deposit {
        amount
        paid
        date
        fullPayment
      }
    }
  }
`

export const GET_CUSTOMER_BOOKINGS = gql`
  query Bookings($ids: [String]) {
    bookings(ids: $ids) {
      id
      hotel {
        name
      }
      checkIn
      checkOut
      packageName
    }
  }
`

export const GET_CUSTOMER_TICKETS = gql`
  query ticket($customerId: String) {
    tickets(customerId: $customerId) {
      id
      company
      date
      customerPayment {
        total {
          amount
        }
      }
      partner
    }
  }
`

export const GET_ACTION_ITEMS = gql`
query actionItems {
  actionItems {
    id
    date
    amount
    paymentType
    remaining
    paid
    description
    itemType
    type
    customerId
    name
  }
}
`

export const GET_HOTEL_LABEL_FIELDS = gql`
{
  hotels {
    id
    name
  }
}
`